<template>
	<page-authorization-container :page="page" no-auth>
		<h2 class="mb-3">Query Subbasins</h2>
		<p>
			Get a list of subbasins from a {{siteText.appName}} dataset whose name starts with the provided characters. A minimum of two starting characters is required.
		</p>
		<p>
			For example, take a dataset containing the following subbasin names: <code>01010001</code>, <code>01010002</code>, <code>02010001</code>, <code>03010001</code>.
			Use <code>01</code> for the starting characters to return data for <code>01010001</code> and <code>01010002</code>.
		</p>

		<b-card no-body class="mb-3 border">
			<b-tabs card>
				<b-tab title="Input">
					<b-table-simple class="border-bottom table-valign-m table-form">
						<b-thead class="bg-light">
							<b-tr>
								<b-th class="min">Parameter Name</b-th>
								<b-th class="min">Query Builder</b-th>
								<b-th>Description</b-th>
								<b-th>Possible Values</b-th>
								<b-th>Value Type</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr>
								<b-td><label for="queryFormat"><code>format</code></label></b-td>
								<b-td>
									<b-form-select id="queryFormat" v-model="query.format" :options="options.format.options" required>
										<template #first>
											<b-form-select-option :value="null" disabled>-- Select a value --</b-form-select-option>
										</template>
									</b-form-select>
								</b-td>
								<b-td>{{options.format.description}}</b-td>
								<b-td>{{joinList(options.format.options)}}</b-td>
								<b-td>{{options.format.type}}</b-td>
							</b-tr>
							<b-tr>
								<b-td><label for="queryDataset"><code>dataset</code></label></b-td>
								<b-td>
									<b-form-select id="queryDataset" v-model="query.dataset" :options="options.dataset.options" required>
										<template #first>
											<b-form-select-option :value="null" disabled>-- Select a value --</b-form-select-option>
										</template>
									</b-form-select>
								</b-td>
								<b-td>{{options.dataset.description}}</b-td>
								<b-td>{{joinList(options.dataset.options)}}</b-td>
								<b-td>{{options.dataset.type}}</b-td>
							</b-tr>
							<b-tr>
								<b-td><label for="queryStartsWith"><code>startsWith</code></label></b-td>
								<b-td>
									<b-form-input id="queryStartsWith" v-model="query.startsWith" type="text" required />
								</b-td>
								<b-td>{{options.startsWith.description}}</b-td>
								<b-td></b-td>
								<b-td>{{options.startsWith.type}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>

					<p class="font-italic"><router-link to="/">API keys</router-link> are not required to run this query.</p>

					<api-linker :url="queryUrl" :options-url="options.url"></api-linker>
				</b-tab>
				<b-tab title="Output">
					<b-table-simple class="border-bottom table-valign-m table-form">
						<b-thead class="bg-light">
							<b-tr>
								<b-th class="min">Parameter Name</b-th>
								<b-th>Description</b-th>
								<b-th class="min">Value Type</b-th>
								<b-th class="min">Units</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr v-for="d in outputs.definitions" :key="d.name">
								<b-td><code>{{d.name}}</code></b-td>
								<b-td>{{d.description}}</b-td>
								<b-td>{{d.type}}</b-td>
								<b-td>{{d.units}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>

					<div>
						<api-linker :options-url="outputs.url" options-type="Output"></api-linker>
					</div>
				</b-tab>
			</b-tabs>
		</b-card>

		<h3 class="mt-5 mb-3">Code Examples</h3>

		<b-card no-body class="mb-3 border">
			<b-tabs card>
				<b-tab title="C#">
					<highlightjs language="csharp" :code="csharpCode" />
				</b-tab>
				<b-tab title="Python">
					<highlightjs language="python" :code="pythonCode" />
				</b-tab>
				<b-tab title="JavaScript">
					<highlightjs language="javascript" :code="javascriptCode" />
				</b-tab>
				<b-tab title="Curl">
					<p>
						Save directly to a file using the <code>-o</code> flag.
					</p>
					<highlightjs :code="curlCode" />
				</b-tab>
			</b-tabs>
		</b-card>
	</page-authorization-container>
</template>

<script>
	export default {
		name: 'DocsQuerySubbasins',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				options: {
					url: 'query/subbasins/input-definitions',
					format: { options: [] },
					dataset: { options: [] },
					startsWith: { }
				},
				outputs: {
					url: 'query/subbasins/output-definitions',
					definitions: []
				},
				query: {
					format: null,
					dataset: null,
					startsWith: '01'
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(this.options.url);
					this.log(response.data);
					this.options.format = this.findInList(response.data, 'name', 'format');
					this.options.dataset = this.findInList(response.data, 'name', 'dataset');
					this.options.startsWith = this.findInList(response.data, 'name', 'startsWith');

					this.query.format = this.options.format.defaultValue;
					this.query.dataset = this.options.dataset.defaultValue;

					const response2 = await this.$http.get(this.outputs.url);
					this.outputs.definitions = response2.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		},
		computed: {
			queryUrl() {
				return `query/subbasins?format=${this.query.format}&dataset=${this.query.dataset}&startsWith=${this.query.startsWith}`;
			},
			csharpCode() {
				let code = `string url = "${this.siteText.appUrl}/${this.queryUrl}";

using var client = new HttpClient();
var message = new HttpRequestMessage(HttpMethod.Get, url);
var result = await client.SendAsync(message);

var str = await result.Content.ReadAsStringAsync();
var data = JsonConvert.DeserializeObject<Dictionary<string, dynamic>>(str);`;

				return code;
			},
			javascriptCode() {
				let code = `import axios from 'axios';
const url = '${this.siteText.appUrl}/${this.queryUrl}';

try {
	const response = await axios.get(url);
	let data = response.data;
} catch (error) {
	console.error(error);
}`;

				return code;
			},
			pythonCode() {
				let host = this.siteText.appUrl.replace('http://', '').replace('https://', '')
				let code = `import http.client

connection = http.client.HTTPSConnection('${host}')

connection.request('GET', '${this.queryUrl}')
response = connection.getresponse()
print(response.read().decode())`;

				return code;
			},
			curlCode() {
				let code = `curl -o subbasins_${this.query.dataset}_${this.query.startsWith}.${this.query.format} "${this.siteText.appUrl}/${this.queryUrl}"`;

				return code;
			}
		}
	}
</script>

<template>
	<div id="app">
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<b-navbar toggleable="lg" type="dark" variant="primary" fixed="top" id="dash-nav">
				<div class="navbar-brand d-flex">
					<div class="acronym mr-3"><router-link to="/">{{siteText.appName}} API</router-link></div>
				</div>

				<b-navbar-toggle target="nav_collapse" />
				<b-collapse is-nav id="nav_collapse">
					<b-navbar-nav class="ml-auto">
						<b-nav-item to="/admin" v-b-tooltip.hover :title="`${siteText.appName} administration`">
							<font-awesome-icon :icon="['fas', 'lock']" fixed-width size="lg" />
						</b-nav-item>
						<b-nav-item-dropdown right no-caret v-if="isAuthenticated" v-b-tooltip.hover title="Account settings">
							<template slot="button-content">
								<font-awesome-icon :icon="['fas', 'user-circle']" fixed-width size="lg" />
							</template>
							<b-dropdown-text style="width:300px">Welcome, <strong>{{this.user.userName}}</strong>!</b-dropdown-text>
							<b-dropdown-divider></b-dropdown-divider>
							<b-dropdown-item-button @click="logout">Log out</b-dropdown-item-button>
						</b-nav-item-dropdown>
					</b-navbar-nav>
				</b-collapse>
			</b-navbar>

			<div id="content-frame">
				<div id="main-panel">
					<router-view />
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
	@import 'app.scss';
</style>

<script>
	export default {
		name: 'app',
		data() {
			return {
				page: {
					loading: true,
					errors: []
				}
			}
		},
		async created() {
			await this.getWebsiteText();
			await this.checkAuth();
		},
		methods: {
			async checkAuth() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('auth/check', this.getTokenHeader());
					this.$store.commit('login', {
						token: localStorage.getItem('auth_token'),
						user: response.data
					});
				} catch (error) {
					this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async getWebsiteText() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('site/text');
					this.$store.commit('setupSite', {
						siteText: response.data.siteText
					});

					if (!this.isNullOrEmpty(response.data.siteText.appName)) {
						document.title = `${response.data.siteText.appName} API Management`;
					}
				} catch (error) {
					this.page.errors = this.logError(error);
				}
			}
		}
	}
</script>

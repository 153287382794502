<template>
	<div id="login">
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<div v-if="user.userName !== undefined && !user.emailConfirmed" class="container-fluid py-4">
				<b-card bg-variant="light" style="max-width:400px" class="mx-auto text-center">
					<error-list :errors="account.confirm.errors"></error-list>
					<b-card-text v-if="!account.confirm.sent">
						Please check your email to confirm your account.
					</b-card-text>
					<b-card-text v-else>
						Confirmation sent! Please check your email.
						If you do not receive anything after one hour, please try again or <router-link to="/help">contact support</router-link>.
					</b-card-text>
					<save-button :saving="account.confirm.saving" text="Re-send email" @click.native="resendConfirmation" />
				</b-card>
			</div>
			<div v-else-if="mfa.show">
				<b-card bg-variant="light" style="max-width:400px" class="mx-auto text-center mb-3">
					<error-list :errors="mfa.errors"></error-list>
					<b-card-text>
						<h5>Account Verification Required</h5>
						<p>
							Please check your email address, <strong>{{ mungeEmailAddress(loginData.user.email) }}</strong>,
							and enter the code from the email into the box below to verify ownership of your account.
						</p>
						<b-form-group label="Verification code">
							<b-form-input v-model="mfa.form.code" type="text" required autofocus></b-form-input>
						</b-form-group>
						<b-form-checkbox v-model="mfa.form.rememberMe">
							Remember this device?
							<span v-b-tooltip.hover.bottom title="Keep this checked if you want to log in without verifying your account through email on this browser/device." class="ml-1">
								<font-awesome-icon icon="info-circle" class="text-info" />
							</span>
						</b-form-checkbox>
					</b-card-text>
					<save-button :saving="mfa.saving" text="Submit" @click.native="confirmMfa" />

					<b-card-text>
						<p class="text-muted mt-2 mb-0" style="font-size: 0.8rem">
						Didn't get the code or can't access the email associated with your account?
						Email us at <a href="mailto:eco.web@tamu.edu">eco.web@tamu.edu</a>.
						</p>
					</b-card-text>
				</b-card>
			</div>
			<div v-else>
				<b-form :validated="page.validated" @submit.prevent="login" class="form-signin py-3" v-if="!recovery.show">
					<h1 v-if="showHeader" class="h3 my-3 font-weight-normal">Please log in</h1>

					<error-list :errors="page.errors">
						<div v-if="page.v1Error">
							<router-link to="/v1/check">Set up your account and import old projects.</router-link> 
						</div>
					</error-list>

					<label for="userName" class="sr-only">User name</label>
					<b-form-input id="userName" type="text" v-model="form.userName" placeholder="User name" required autofocus />

					<label for="password" class="sr-only">Password</label>
					<b-form-input id="password" type="password" v-model="form.password" placeholder="Password" required />

					<div class="mt-3">
						<save-button :saving="page.saving" @click.native="page.validated = true" text="Log in" />
					</div>
					<p class="mt-3 mb-0 text-muted">
						Forgot your password? Visit the main {{siteText.appName}} website to manage your account.
					</p>
				</b-form>

				<b-form :validated="page.validated" @submit.prevent="recover" class="form-signin py-3" v-if="recovery.show">
					<h1 v-if="showHeader" class="h3 my-3 font-weight-normal">Recover your account</h1>

					<div v-if="recovery.sent">
						<p>
							Request received. If an account matches the email address provided, you will receive instructions for resetting your password.
							Please contact support if you have any problems.
						</p>
					</div>
					<div v-else>
						<p>
							Enter the email address associated with your account and we'll send you instructions for resetting your password.
						</p>

						<error-list :errors="page.errors"></error-list>

						<label for="email" class="sr-only">Email address</label>
						<b-form-input id="email" type="email" v-model="recovery.email" placeholder="Email address" required autofocus />

						<div class="mt-3">
							<save-button :saving="page.saving" @click.native="page.validated = true" text="Send recovery information" />
						</div>
					</div>

					<p class="mt-3 mb-0">
						<b-link @click="recovery.show = false">Back to log-in form</b-link>
					</p>
				</b-form>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'LoginForm',
		props: {
			showHeader: {
				type: Boolean,
				default: false
			},
			redirect: {
				type: String,
				default: '/projects'
			},
			noRedirect: {
				type: Boolean,
				default: false
			},
			explicitRedirect: {
				type: String,
				default: ''
			}
		},
		data: function() {
			return {
				page: {
					errors: [],
					loading: false,
					validated: false,
					saving: false,
					v1Error: false
				},
				form: {
					userName: '',
					password: '',
					deviceToken: null
				},
				recovery: {
					email: '',
					show: false,
					sent: false
				},
				account: {
					confirm: {
						errors: [],
						saving: false,
						sent: false
					}
				},
				loginData: {},
				mfa: {
					show: false,
					errors: [],
					saving: false,
					form: {
						code: null,
						rememberMe: true
					}
				}
			}
		},
		methods: {
			async login() {
				this.page.errors = [];
				this.page.saving = true;
				this.page.validated = true;
				this.page.v1Error = false;

				try {
					this.form.deviceToken = this.getRememberMe(this.form.userName);
					this.log(this.form);
					const response = await this.$http.post('auth/login', this.form);
					this.log(response.data);

					this.loginData = {
						token: response.data.auth_token,
						user: response.data.identity
					};

					if (response.data.multiFactorValidated) {
						this.loginUser();
					} else {
						this.mfa.show = true;
					}
				} catch (error) {
					this.page.errors = this.logError(error);
					if (this.page.errors.length > 0 && this.page.errors[0].includes('version 1')) {
						this.page.v1Error = true;
					}
					this.logout(false);
					this.$emit('success', false);
				}

				this.page.saving = false;
			},
			async confirmMfa() {
				this.mfa.errors = [];
				this.mfa.saving = true;

				if (this.isNullOrEmpty(this.mfa.form.code)) {
					this.page.errors.push('Please enter the code from your email.');
				} else {
					try {
						let data = {
							code: this.mfa.form.code,
							token: null
						};

						if (this.mfa.form.rememberMe) {
							let token = this.setRememberMe(this.loginData.user.userName);
							data.token = token;
						}

						let tempHeaders = {
							headers: { 'Authorization': 'Bearer ' + this.loginData.token }
						};

						this.log(data);

						const response = await this.$http.post('auth/confirmmfa', data, tempHeaders);
						this.log(response.data);

						this.loginUser();
					} catch (error) {
						this.mfa.errors = this.logError(error);
						this.logout(false);
						this.$emit('success', false);
					}
				}

				this.mfa.saving = false;
			},
			loginUser() {
				localStorage.setItem('auth_token', this.loginData.token);
				this.$store.commit('login', this.loginData);
				this.page.validated = false;

				if (!this.noRedirect) {
					if (!this.isNullOrEmpty(this.explicitRedirect)) {
						this.$router.push(this.explicitRedirect).catch(err => { });
					} else {
						this.page.loading = true;
						window.location.reload(true);
					}
				}

				this.$emit('success', true);
			},
			loadRecoveryForm() {
				this.page.errors = [];
				this.page.validated = false;
				this.recovery.show = true;
			},
			async recover() {
				this.page.errors = [];
				this.page.saving = true;
				this.page.validated = true;

				try {
					const response = await this.$http.post('account/forgotpassword', { email: this.recovery.email });
					this.recovery.sent = true;
					this.page.validated = false;
				} catch (error) {
					this.page.errors = this.logError(error);
				}

				this.page.saving = false;
			},
			async resendConfirmation() {
				this.account.confirm.errors = [];
				this.account.confirm.saving = true;

				try {
					const response = await this.$http.post('account/resendconfirmation', {}, this.getTokenHeader());
					this.account.confirm.sent = true;
				} catch (error) {
					this.account.confirm.errors = this.logError(error);
				}

				this.account.confirm.saving = false;
			},
			mungeEmailAddress(s) {
				var i = s.indexOf('@');
				var startIndex = i * .2 | 0;
				var endIndex = i * .9 | 0;
				return s.slice(0, startIndex) +
					s.slice(startIndex, endIndex).replace(/./g, '*') +
					s.slice(endIndex);
			}
		}
	}
</script>

<style scoped>
	.form-signin {
		width: 100%;
		max-width: 400px;
		margin: auto;
	}

	.form-signin .form-control {
		position: relative;
		box-sizing: border-box;
		height: auto;
		padding: 10px;
	}

		.form-signin .form-control:focus {
			z-index: 2;
		}

	.form-signin input[type="text"] {
		margin-bottom: -1px;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}

	.form-signin input[type="password"] {
		margin-bottom: 10px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
</style>

<template>
	<page-authorization-container :page="page" no-auth>
		<h2 class="mb-3">Submit a Project</h2>
		<p>
			This API method will create a {{siteText.appName}} project given the inputs below, set up your model inputs, run SWAT, process model output data, and return
			reports specified in the inputs.
		</p>

		<b-card no-body class="mb-3 border">
			<b-tabs card>
				<b-tab title="Input Builder">
					<p>
						Use the form below to build a sample input JSON object.
						Note if a parameter is not required, it may be omitted from your object definition.
						<br />Use the input definitions API to get all parameter options, descriptions, and units.
					</p>

					<error-list :errors="page.example.errors"></error-list>

					<div class="d-flex align-items-center mb-3">
						<div class="mr-1">
							<api-linker :options-url="inputs.url"></api-linker>
						</div>
						<div class="ml-auto mr-3">
							<b-form-checkbox v-model="show.onlyBasic" switch v-b-tooltip.hover="'Some of the inputs for submitting a project are optional and uncommon for most users. Beginners may use this switch to hide/show some of these advanced input parameters.'">
								Show only basic parameters
							</b-form-checkbox>
						</div>
						<div>
							<save-button variant="primary" @click.native="loadExample" @saving="page.example.loading" type="button"
										 text="Load Example Project"
										 v-b-tooltip.hover="'Fill input parameters with an example watershed using minimal, basic inputs only.'"></save-button>
						</div>
					</div>

					<b-table-simple class="border-bottom table-valign-m table-form" stacked="lg" small>
						<b-thead class="bg-light">
							<b-tr>
								<b-th class="min">Parameter Name</b-th>
								<b-th>Input Builder</b-th>
								<b-th>Description</b-th>
								<b-th class="min">Value Type</b-th>
								<b-th class="min text-center">Required</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr>
								<b-td><label for="dataset"><code>dataset</code></label></b-td>
								<b-td>
									<b-form-select id="dataset" v-model="query.dataset" :options="getInput('dataset').options" required>
										<template #first>
											<b-form-select-option :value="null" disabled>-- Select a value --</b-form-select-option>
										</template>
									</b-form-select>
								</b-td>
								<b-td>{{getInput('dataset').description}}</b-td>
								<b-td>{{getInput('dataset').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('dataset').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="query.dataset === 'HUC14'">
								<b-td><label for="disaggregateComids"><code>disaggregateComids</code></label></b-td>
								<b-td><b-form-checkbox id="disaggregateComids" v-model="query.disaggregateComids"></b-form-checkbox></b-td>
								<b-td>{{getInput('disaggregateComids').description}}</b-td>
								<b-td>{{getInput('disaggregateComids').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('disaggregateComids').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr>
								<b-td><label for="downstreamSubbasin"><code>downstreamSubbasin</code></label></b-td>
								<b-td>
									<b-typeahead ref="downstreamSubbasinTypeahead" placeholder="Type to search..." :minMatchingChars="2"
												 :data="dynamicOptions.downstreamSubbasins == null ? [] : dynamicOptions.downstreamSubbasins"
												 v-model="downstreamSubbasinSearch"
												 :serializer="s => s.name"
												 :max-matches="100">
									</b-typeahead>
								</b-td>
								<b-td>{{getInput('downstreamSubbasin').description}}</b-td>
								<b-td>{{getInput('downstreamSubbasin').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('downstreamSubbasin').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="!show.onlyBasic">
								<b-td><label for="upstreamSubbasins"><code>upstreamSubbasins</code></label></b-td>
								<b-td>
									<b-typeahead placeholder="Type to search..." :minMatchingChars="2"
												 :data="dynamicOptions.upstreamSubbasins == null ? [] : dynamicOptions.upstreamSubbasins"
												 v-model="upstreamSubbasinsSearch"
												 :serializer="s => s.name"
												 :max-matches="100">
										<template slot="append">
											<b-button @click="addUpstreamSubbasin" variant="secondary" v-b-tooltip.hover="'Add upstream subbasin'">
												<font-awesome-icon :icon="['fas', 'plus']" />
											</b-button>
										</template>
									</b-typeahead>
									<div>
										<b-button v-for="(s, i) in query.upstreamSubbasins" :key="i" variant="light" size="sm" class="mr-2 my-1" v-b-tooltip.hover="'Click to remove from list'" @click="removeUpstreamSubbasin(s)">
											{{s}}
											<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
										</b-button>
									</div>
								</b-td>
								<b-td>{{getInput('upstreamSubbasins').description}}</b-td>
								<b-td>{{getInput('upstreamSubbasins').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('upstreamSubbasins').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="!show.onlyBasic">
								<b-td><label for="upstreamDataSource"><code>upstreamDataSource</code></label></b-td>
								<b-td>
									<b-form-select id="upstreamDataSource" v-model="query.upstreamDataSource" @change="setSimulationDates">
										<template #first>
											<b-form-select-option :value="null">-- Select a value --</b-form-select-option>
										</template>
										<b-form-select-option v-for="(o, k) in upstreamDataSourceOptions" :key="k" :value="o.value">{{o.value}}</b-form-select-option>
									</b-form-select>
									<div v-if="selectedUpstreamDataSource !== null" class="mt-1 text-muted ml-1">
										<small>{{selectedUpstreamDataSource.startDate | date('MM/DD/YYYY')}} - {{selectedUpstreamDataSource.endDate | date('MM/DD/YYYY')}}</small>
									</div>
								</b-td>
								<b-td>{{getInput('upstreamDataSource').description}}</b-td>
								<b-td>{{getInput('upstreamDataSource').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('upstreamDataSource').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr class="bg-light pointer" @click="show.setHrus = !show.setHrus" title="Click to toggle parameters">
								<b-td>
									<font-awesome-icon :icon="show.setHrus ? ['fas', 'minus'] : ['fas', 'plus']" class="text-muted mr-2" />
									<code>setHrus</code>
								</b-td>
								<b-td></b-td>
								<b-td>{{getInput('setHrus').description}}</b-td>
								<b-td>{{getInput('setHrus').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('setHrus').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.setHrus">
								<b-td class="min"><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" /><label for="setHrusMethod"><code>method</code></label></b-td>
								<b-td>
									<b-form-select id="setHrusMethod" v-model="query.setHrus.method" :options="getInput('method', shortcuts.setHrus).options" required>
										<template #first>
											<b-form-select-option :value="null" disabled>-- Select a value --</b-form-select-option>
										</template>
									</b-form-select>
								</b-td>
								<b-td>{{getInput('method', shortcuts.setHrus).description}}</b-td>
								<b-td>{{getInput('method', shortcuts.setHrus).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('method', shortcuts.setHrus).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.setHrus">
								<b-td class="min"><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" /><label for="setHrusTarget"><code>target</code></label></b-td>
								<b-td>
									<b-form-input id="setHrusTarget" v-model="query.setHrus.target" type="number" step="any" required />
								</b-td>
								<b-td>{{getInput('target', shortcuts.setHrus).description}}</b-td>
								<b-td>{{getInput('target', shortcuts.setHrus).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('target', shortcuts.setHrus).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.setHrus">
								<b-td class="min"><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" /><label for="setHrusUnits"><code>units</code></label></b-td>
								<b-td>
									<b-form-select id="setHrusUnits" v-model="query.setHrus.units" :options="getInput('units', shortcuts.setHrus).options" required>
										<template #first>
											<b-form-select-option :value="null" disabled>-- Select a value --</b-form-select-option>
										</template>
									</b-form-select>
								</b-td>
								<b-td>{{getInput('units', shortcuts.setHrus).description}}</b-td>
								<b-td>{{getInput('units', shortcuts.setHrus).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('units', shortcuts.setHrus).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.setHrus && !show.onlyBasic">
								<b-td class="min"><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" /><label for="setHrusExemptLanduse"><code>exemptLanduse</code></label></b-td>
								<b-td>
									<b-typeahead placeholder="Type to search..." :minMatchingChars="1" showAllResults showOnFocus
												 :data="dynamicOptions.exemptLanduse == null ? [] : dynamicOptions.exemptLanduse"
												 v-model="exemptLanduseSearch"
												 :serializer="s => s.code"
												 :max-matches="100">
										<template slot="append">
											<b-button @click="addExemptLanduse" variant="secondary" v-b-tooltip.hover="'Add landuse'">
												<font-awesome-icon :icon="['fas', 'plus']" />
											</b-button>
										</template>
										<template slot="suggestion" slot-scope="{ data, htmlText }">
											<span v-html="htmlText"></span>  - {{data.description}}
										</template>
									</b-typeahead>
									<div>
										<b-button v-for="(elu, j) in query.setHrus.exemptLanduse" :key="j" @click="removeExemptLanduse(elu)" variant="light" size="sm" class="mr-2 my-1" v-b-tooltip.hover="'Click to remove from list'">
											{{elu}}
											<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
										</b-button>
									</div>
								</b-td>
								<b-td>{{getInput('exemptLanduse', shortcuts.setHrus).description}}</b-td>
								<b-td>{{getInput('exemptLanduse', shortcuts.setHrus).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('exemptLanduse', shortcuts.setHrus).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.setHrus && !show.onlyBasic" class="thick-border">
								<b-td class="min"><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" /><label for="setHrusNoAreaRedistribution"><code>noAreaRedistribution</code></label></b-td>
								<b-td>
									<b-typeahead placeholder="Type to search..." :minMatchingChars="1" showAllResults showOnFocus
												 :data="dynamicOptions.noAreaRedistribution == null ? [] : dynamicOptions.noAreaRedistribution"
												 v-model="noAreaRedistributionSearch"
												 :serializer="s => s.code"
												 :max-matches="100">
										<template slot="append">
											<b-button @click="addNoAreaRedistribution" variant="secondary" v-b-tooltip.hover="'Add landuse'">
												<font-awesome-icon :icon="['fas', 'plus']" />
											</b-button>
										</template>
										<template slot="suggestion" slot-scope="{ data, htmlText }">
											<span v-html="htmlText"></span>  - {{data.description}}
										</template>
									</b-typeahead>
									<div>
										<b-button v-for="(elu, j) in query.setHrus.noAreaRedistribution" :key="j" @click="removeNoAreaRedistribution(elu)" variant="light" size="sm" class="mr-2 my-1" v-b-tooltip.hover="'Click to remove from list'">
											{{elu}}
											<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
										</b-button>
									</div>
								</b-td>
								<b-td>{{getInput('noAreaRedistribution', shortcuts.setHrus).description}}</b-td>
								<b-td>{{getInput('noAreaRedistribution', shortcuts.setHrus).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('noAreaRedistribution', shortcuts.setHrus).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr>
								<b-td><label for="weatherDataset"><code>weatherDataset</code></label></b-td>
								<b-td>
									<b-form-select id="weatherDataset" v-model="query.weatherDataset" @change="setSimulationDates">
										<template #first>
											<b-form-select-option :value="null" disabled>-- Select a value --</b-form-select-option>
										</template>
										<b-form-select-option v-for="(o, k) in weatherDatasetOptions" :key="k" :value="o.value">{{o.value}}</b-form-select-option>
									</b-form-select>
									<div v-if="selectedWeatherDataset !== null" class="mt-1 text-muted ml-1">
										<small>{{selectedWeatherDataset.startDate | date('MM/DD/YYYY')}} - {{selectedWeatherDataset.endDate | date('MM/DD/YYYY')}}</small>
									</div>
								</b-td>
								<b-td>{{getInput('weatherDataset').description}}</b-td>
								<b-td>{{getInput('weatherDataset').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('weatherDataset').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="!show.onlyBasic">
								<b-td><label for="climateScenario"><code>climateScenario</code></label></b-td>
								<b-td>
									<b-form-select id="climateScenario" v-model="query.climateScenario" :options="getInput('climateScenario').options">
										<template #first>
											<b-form-select-option :value="null" disabled>-- Select a value --</b-form-select-option>
										</template>
									</b-form-select>
								</b-td>
								<b-td>{{getInput('climateScenario').description}}</b-td>
								<b-td>{{getInput('climateScenario').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('climateScenario').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr>
								<b-td><label for="startingSimulationDate"><code>startingSimulationDate</code></label></b-td>
								<b-td>
									<b-form-input id="startingSimulationDate" v-model="query.startingSimulationDate" type="date" />
									<font-awesome-icon :icon="['fas', 'circle-question']" class="ml-2 text-muted" v-b-tooltip.hover="notes.dateFormat" />
								</b-td>
								<b-td>{{getInput('startingSimulationDate').description}}</b-td>
								<b-td>{{getInput('startingSimulationDate').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('startingSimulationDate').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr>
								<b-td><label for="endingSimulationDate"><code>endingSimulationDate</code></label></b-td>
								<b-td>
									<b-form-input id="endingSimulationDate" v-model="query.endingSimulationDate" type="date" />
									<font-awesome-icon :icon="['fas', 'circle-question']" class="ml-2 text-muted" v-b-tooltip.hover="notes.dateFormat" />
								</b-td>
								<b-td>{{getInput('endingSimulationDate').description}}</b-td>
								<b-td>{{getInput('endingSimulationDate').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('endingSimulationDate').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr>
								<b-td><label for="warmupYears"><code>warmupYears</code></label></b-td>
								<b-td>
									<b-form-input id="warmupYears" v-model="query.warmupYears" type="number" />
								</b-td>
								<b-td>{{getInput('warmupYears').description}}</b-td>
								<b-td>{{getInput('warmupYears').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('warmupYears').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr>
								<b-td><label for="outputPrintSetting"><code>outputPrintSetting</code></label></b-td>
								<b-td>
									<b-form-select id="outputPrintSetting" v-model="query.outputPrintSetting" :options="getInput('outputPrintSetting').options">
										<template #first>
											<b-form-select-option :value="null" disabled>-- Select a value --</b-form-select-option>
										</template>
									</b-form-select>
								</b-td>
								<b-td>{{getInput('outputPrintSetting').description}}</b-td>
								<b-td>{{getInput('outputPrintSetting').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('outputPrintSetting').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="!show.onlyBasic">
								<b-td><label for="swatVersion"><code>swatVersion</code></label></b-td>
								<b-td>
									<b-form-select id="swatVersion" v-model="query.swatVersion" :options="getInput('swatVersion').options">
										<template #first>
											<b-form-select-option :value="null" disabled>-- Select a value --</b-form-select-option>
										</template>
									</b-form-select>
								</b-td>
								<b-td>{{getInput('swatVersion').description}}</b-td>
								<b-td>{{getInput('swatVersion').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('swatVersion').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="!show.onlyBasic">
								<b-td><label for="writeSwatEditorDb"><code>writeSwatEditorDb</code></label></b-td>
								<b-td>
									<b-form-select id="writeSwatEditorDb" v-model="query.writeSwatEditorDb" :options="getInput('writeSwatEditorDb').options">
										<template #first>
											<b-form-select-option :value="null" disabled>-- Select a value --</b-form-select-option>
										</template>
									</b-form-select>
								</b-td>
								<b-td>{{getInput('writeSwatEditorDb').description}}</b-td>
								<b-td>{{getInput('writeSwatEditorDb').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('writeSwatEditorDb').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="!show.onlyBasic">
								<b-td><label for="noOutputProcessing"><code>noOutputProcessing</code></label></b-td>
								<b-td>
									<b-form-checkbox id="noOutputProcessing" v-model="query.noOutputProcessing"></b-form-checkbox>
								</b-td>
								<b-td>{{getInput('noOutputProcessing').description}}</b-td>
								<b-td>{{getInput('noOutputProcessing').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('noOutputProcessing').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="!show.onlyBasic">
								<b-td><label for="noModelRun"><code>noModelRun</code></label></b-td>
								<b-td>
									<b-form-checkbox id="noModelRun" v-model="query.noModelRun"></b-form-checkbox>
								</b-td>
								<b-td>{{getInput('noModelRun').description}}</b-td>
								<b-td>{{getInput('noModelRun').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('noModelRun').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="!show.onlyBasic">
								<b-td><label for="useInWebInterface"><code>useInWebInterface</code></label></b-td>
								<b-td>
									<b-form-checkbox id="useInWebInterface" v-model="query.useInWebInterface"></b-form-checkbox>
								</b-td>
								<b-td>{{getInput('useInWebInterface').description}}</b-td>
								<b-td>{{getInput('useInWebInterface').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('useInWebInterface').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="!show.onlyBasic">
								<b-td><label for="projectName"><code>projectName</code></label></b-td>
								<b-td>
									<b-form-input id="projectName" v-model="query.projectName" type="text" />
								</b-td>
								<b-td>{{getInput('projectName').description}}</b-td>
								<b-td>{{getInput('projectName').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('projectName').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>

							<b-tr class="bg-light pointer" @click="show.reportData = !show.reportData" title="Click to toggle parameters">
								<b-td>
									<font-awesome-icon :icon="show.reportData ? ['fas', 'minus'] : ['fas', 'plus']" class="text-muted mr-2" />
									<code>reportData</code>
								</b-td>
								<b-td></b-td>
								<b-td>{{getInput('reportData').description}}</b-td>
								<b-td>{{getInput('reportData').type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('reportData').required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.reportData">
								<b-td class="min"><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" /><label for="reportDataFormat"><code>formats</code></label></b-td>
								<b-td>
									<b-typeahead placeholder="Type to search..." :minMatchingChars="1" showAllResults showOnFocus
												 :data="getInput('formats', shortcuts.reportData).options"
												 v-model="reportFormatsSearch"
												 :max-matches="100">
										<template slot="append">
											<b-button @click="addToArray(reportFormatsSearch, query.reportData.formats)" variant="secondary" v-b-tooltip.hover="'Add format'">
												<font-awesome-icon :icon="['fas', 'plus']" />
											</b-button>
										</template>
									</b-typeahead>
									<div>
										<b-button v-for="(s, i) in query.reportData.formats" :key="i"
												  @click="query.reportData.formats = removeFromArray(s, query.reportData.formats)" variant="light" size="sm" class="mr-2 my-1" v-b-tooltip.hover="'Click to remove from list'">
											{{s}}
											<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
										</b-button>
									</div>
								</b-td>
								<b-td>{{getInput('formats', shortcuts.reportData).description}}</b-td>
								<b-td>{{getInput('formats', shortcuts.reportData).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('formats', shortcuts.reportData).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.reportData">
								<b-td class="min"><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" /><label for="reportDataUnits"><code>units</code></label></b-td>
								<b-td>
									<b-form-select id="reportDataUnits" v-model="query.reportData.units" :options="getInput('units', shortcuts.reportData).options" required>
										<template #first>
											<b-form-select-option :value="null" disabled>-- Select a value --</b-form-select-option>
										</template>
									</b-form-select>
								</b-td>
								<b-td>{{getInput('units', shortcuts.reportData).description}}</b-td>
								<b-td>{{getInput('units', shortcuts.reportData).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('units', shortcuts.reportData).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.reportData">
								<b-td class="min"><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" /><code>inputs</code></b-td>
								<b-td></b-td>
								<b-td>{{getInput('inputs', shortcuts.reportData).description}}</b-td>
								<b-td>{{getInput('inputs', shortcuts.reportData).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('inputs', shortcuts.reportData).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.reportData">
								<b-td class="min">
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<label for="includePhysicalProperties"><code>includePhysicalProperties</code></label>
								</b-td>
								<b-td><b-form-checkbox id="includePhysicalProperties" v-model="query.reportData.inputs.includePhysicalProperties"></b-form-checkbox></b-td>
								<b-td>{{getInput('includePhysicalProperties', shortcuts.reportDataInputs).description}}</b-td>
								<b-td>{{getInput('includePhysicalProperties', shortcuts.reportDataInputs).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('includePhysicalProperties', shortcuts.reportDataInputs).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.reportData">
								<b-td class="min"><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" /><code>outputs</code></b-td>
								<b-td></b-td>
								<b-td>{{getInput('outputs', shortcuts.reportData).description}}</b-td>
								<b-td>{{getInput('outputs', shortcuts.reportData).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('outputs', shortcuts.reportData).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.reportData" class="bg-light">
								<b-td class="min">
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<code>rch</code>
								</b-td>
								<b-td></b-td>
								<b-td>{{getInput('rch', shortcuts.reportDataOutputs).description}}</b-td>
								<b-td>{{getInput('rch', shortcuts.reportDataOutputs).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('rch', shortcuts.reportDataOutputs).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.reportData">
								<b-td class="min">
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<code>parameters</code>
								</b-td>
								<b-td>
									<b-typeahead placeholder="Type to search..." :minMatchingChars="1" showAllResults showOnFocus
												 :data="getInput('parameters', shortcuts.reportDataRch).options"
												 v-model="rchParametersSearch"
												 :serializer="s => s.value"
												 :max-matches="100">
										<template slot="append">
											<b-button @click="addToArray(rchParametersSearch, query.reportData.outputs.rch.parameters)" variant="secondary" v-b-tooltip.hover="'Add parameter'">
												<font-awesome-icon :icon="['fas', 'plus']" />
											</b-button>
										</template>
										<template slot="suggestion" slot-scope="{ data, htmlText }">
											<span v-html="htmlText"></span>
											<br />
											<small>{{data.description}}</small>
										</template>
									</b-typeahead>
									<div>
										<b-button v-for="(s, i) in query.reportData.outputs.rch.parameters" :key="i"
												  @click="query.reportData.outputs.rch.parameters = removeFromArray(s, query.reportData.outputs.rch.parameters)" variant="light" size="sm" class="mr-2 my-1" v-b-tooltip.hover="'Click to remove from list'">
											{{s}}
											<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
										</b-button>
									</div>
								</b-td>
								<b-td>{{getInput('parameters', shortcuts.reportDataRch).description}}</b-td>
								<b-td>{{getInput('parameters', shortcuts.reportDataRch).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('parameters', shortcuts.reportDataRch).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.reportData">
								<b-td class="min">
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<code>statistics</code>
								</b-td>
								<b-td>
									<b-typeahead placeholder="Type to search..." :minMatchingChars="1" showAllResults showOnFocus
												 :data="getInput('statistics', shortcuts.reportDataRch).options"
												 v-model="rchStatisticsSearch"
												 :serializer="s => s.value"
												 :max-matches="100">
										<template slot="append">
											<b-button @click="addToArray(rchStatisticsSearch, query.reportData.outputs.rch.statistics)" variant="secondary" v-b-tooltip.hover="'Add parameter'">
												<font-awesome-icon :icon="['fas', 'plus']" />
											</b-button>
										</template>
										<template slot="suggestion" slot-scope="{ data, htmlText }">
											<span v-html="htmlText"></span>
											<br />
											<small>{{data.description}}</small>
										</template>
									</b-typeahead>
									<div>
										<b-button v-for="(s, i) in query.reportData.outputs.rch.statistics" :key="i"
												  @click="query.reportData.outputs.rch.statistics = removeFromArray(s, query.reportData.outputs.rch.statistics)" variant="light" size="sm" class="mr-2 my-1" v-b-tooltip.hover="'Click to remove from list'">
											{{s}}
											<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
										</b-button>
									</div>
								</b-td>
								<b-td>{{getInput('statistics', shortcuts.reportDataRch).description}}</b-td>
								<b-td>{{getInput('statistics', shortcuts.reportDataRch).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('statistics', shortcuts.reportDataRch).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.reportData">
								<b-td class="min">
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<code>subbasins</code>
								</b-td>
								<b-td>
									<b-typeahead placeholder="Type to search..." :minMatchingChars="2"
												 :data="dynamicOptions.rchSubbasins == null ? [] : dynamicOptions.rchSubbasins"
												 v-model="rchSubbasinsSearch"
												 :serializer="s => s.name"
												 :max-matches="100">
										<template slot="append">
											<b-button @click="addToArray(rchSubbasinsSearch, query.reportData.outputs.rch.subbasins)" variant="secondary" v-b-tooltip.hover="'Add parameter'">
												<font-awesome-icon :icon="['fas', 'plus']" />
											</b-button>
										</template>
									</b-typeahead>
									<div>
										<b-button v-for="(s, i) in query.reportData.outputs.rch.subbasins" :key="i"
												  @click="query.reportData.outputs.rch.subbasins = removeFromArray(s, query.reportData.outputs.rch.subbasins)" variant="light" size="sm" class="mr-2 my-1" v-b-tooltip.hover="'Click to remove from list'">
											{{s}}
											<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
										</b-button>
									</div>
								</b-td>
								<b-td>{{getInput('subbasins', shortcuts.reportDataRch).description}}</b-td>
								<b-td>{{getInput('subbasins', shortcuts.reportDataRch).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('subbasins', shortcuts.reportDataRch).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.reportData" class="bg-light">
								<b-td class="min">
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<code>sub</code>
								</b-td>
								<b-td></b-td>
								<b-td>{{getInput('sub', shortcuts.reportDataOutputs).description}}</b-td>
								<b-td>{{getInput('sub', shortcuts.reportDataOutputs).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('sub', shortcuts.reportDataOutputs).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.reportData">
								<b-td class="min">
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<code>parameters</code>
								</b-td>
								<b-td>
									<b-typeahead placeholder="Type to search..." :minMatchingChars="1" showAllResults showOnFocus
												 :data="getInput('parameters', shortcuts.reportDataSub).options"
												 v-model="subParametersSearch"
												 :serializer="s => s.value"
												 :max-matches="100">
										<template slot="append">
											<b-button @click="addToArray(subParametersSearch, query.reportData.outputs.sub.parameters)" variant="secondary" v-b-tooltip.hover="'Add parameter'">
												<font-awesome-icon :icon="['fas', 'plus']" />
											</b-button>
										</template>
										<template slot="suggestion" slot-scope="{ data, htmlText }">
											<span v-html="htmlText"></span>
											<br />
											<small>{{data.description}}</small>
										</template>
									</b-typeahead>
									<div>
										<b-button v-for="(s, i) in query.reportData.outputs.sub.parameters" :key="i"
												  @click="query.reportData.outputs.sub.parameters = removeFromArray(s, query.reportData.outputs.sub.parameters)" variant="light" size="sm" class="mr-2 my-1" v-b-tooltip.hover="'Click to remove from list'">
											{{s}}
											<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
										</b-button>
									</div>
								</b-td>
								<b-td>{{getInput('parameters', shortcuts.reportDataSub).description}}</b-td>
								<b-td>{{getInput('parameters', shortcuts.reportDataSub).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('parameters', shortcuts.reportDataSub).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.reportData">
								<b-td class="min">
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<code>statistics</code>
								</b-td>
								<b-td>
									<b-typeahead placeholder="Type to search..." :minMatchingChars="1" showAllResults showOnFocus
												 :data="getInput('statistics', shortcuts.reportDataSub).options"
												 v-model="subStatisticsSearch"
												 :serializer="s => s.value"
												 :max-matches="100">
										<template slot="append">
											<b-button @click="addToArray(subStatisticsSearch, query.reportData.outputs.sub.statistics)" variant="secondary" v-b-tooltip.hover="'Add parameter'">
												<font-awesome-icon :icon="['fas', 'plus']" />
											</b-button>
										</template>
										<template slot="suggestion" slot-scope="{ data, htmlText }">
											<span v-html="htmlText"></span>
											<br />
											<small>{{data.description}}</small>
										</template>
									</b-typeahead>
									<div>
										<b-button v-for="(s, i) in query.reportData.outputs.sub.statistics" :key="i"
												  @click="query.reportData.outputs.sub.statistics = removeFromArray(s, query.reportData.outputs.sub.statistics)" variant="light" size="sm" class="mr-2 my-1" v-b-tooltip.hover="'Click to remove from list'">
											{{s}}
											<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
										</b-button>
									</div>
								</b-td>
								<b-td>{{getInput('statistics', shortcuts.reportDataSub).description}}</b-td>
								<b-td>{{getInput('statistics', shortcuts.reportDataSub).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('statistics', shortcuts.reportDataSub).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.reportData">
								<b-td class="min">
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<code>subbasins</code>
								</b-td>
								<b-td>
									<b-typeahead placeholder="Type to search..." :minMatchingChars="2"
												 :data="dynamicOptions.subSubbasins == null ? [] : dynamicOptions.subSubbasins"
												 v-model="subSubbasinsSearch"
												 :serializer="s => s.name"
												 :max-matches="100">
										<template slot="append">
											<b-button @click="addToArray(subSubbasinsSearch, query.reportData.outputs.sub.subbasins)" variant="secondary" v-b-tooltip.hover="'Add parameter'">
												<font-awesome-icon :icon="['fas', 'plus']" />
											</b-button>
										</template>
									</b-typeahead>
									<div>
										<b-button v-for="(s, i) in query.reportData.outputs.sub.subbasins" :key="i"
												  @click="query.reportData.outputs.sub.subbasins = removeFromArray(s, query.reportData.outputs.sub.subbasins)" variant="light" size="sm" class="mr-2 my-1" v-b-tooltip.hover="'Click to remove from list'">
											{{s}}
											<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
										</b-button>
									</div>
								</b-td>
								<b-td>{{getInput('subbasins', shortcuts.reportDataSub).description}}</b-td>
								<b-td>{{getInput('subbasins', shortcuts.reportDataSub).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('subbasins', shortcuts.reportDataSub).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.reportData" class="bg-light">
								<b-td class="min">
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<code>hru</code>
								</b-td>
								<b-td></b-td>
								<b-td>{{getInput('hru', shortcuts.reportDataOutputs).description}}</b-td>
								<b-td>{{getInput('hru', shortcuts.reportDataOutputs).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('hru', shortcuts.reportDataOutputs).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.reportData">
								<b-td class="min">
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<code>parameters</code>
								</b-td>
								<b-td>
									<b-typeahead placeholder="Type to search..." :minMatchingChars="1" showAllResults showOnFocus
												 :data="getInput('parameters', shortcuts.reportDataHru).options"
												 v-model="hruParametersSearch"
												 :serializer="s => s.value"
												 :max-matches="100">
										<template slot="append">
											<b-button @click="addToArray(hruParametersSearch, query.reportData.outputs.hru.parameters)" variant="secondary" v-b-tooltip.hover="'Add parameter'">
												<font-awesome-icon :icon="['fas', 'plus']" />
											</b-button>
										</template>
										<template slot="suggestion" slot-scope="{ data, htmlText }">
											<span v-html="htmlText"></span>
											<br />
											<small>{{data.description}}</small>
										</template>
									</b-typeahead>
									<div>
										<b-button v-for="(s, i) in query.reportData.outputs.hru.parameters" :key="i"
												  @click="query.reportData.outputs.hru.parameters = removeFromArray(s, query.reportData.outputs.hru.parameters)" variant="light" size="sm" class="mr-2 my-1" v-b-tooltip.hover="'Click to remove from list'">
											{{s}}
											<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
										</b-button>
									</div>
								</b-td>
								<b-td>{{getInput('parameters', shortcuts.reportDataHru).description}}</b-td>
								<b-td>{{getInput('parameters', shortcuts.reportDataHru).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('parameters', shortcuts.reportDataHru).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.reportData">
								<b-td class="min">
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<code>statistics</code>
								</b-td>
								<b-td>
									<b-typeahead placeholder="Type to search..." :minMatchingChars="1" showAllResults showOnFocus
												 :data="getInput('statistics', shortcuts.reportDataHru).options"
												 v-model="hruStatisticsSearch"
												 :serializer="s => s.value"
												 :max-matches="100">
										<template slot="append">
											<b-button @click="addToArray(hruStatisticsSearch, query.reportData.outputs.hru.statistics)" variant="secondary" v-b-tooltip.hover="'Add parameter'">
												<font-awesome-icon :icon="['fas', 'plus']" />
											</b-button>
										</template>
										<template slot="suggestion" slot-scope="{ data, htmlText }">
											<span v-html="htmlText"></span>
											<br />
											<small>{{data.description}}</small>
										</template>
									</b-typeahead>
									<div>
										<b-button v-for="(s, i) in query.reportData.outputs.hru.statistics" :key="i"
												  @click="query.reportData.outputs.hru.statistics = removeFromArray(s, query.reportData.outputs.hru.statistics)" variant="light" size="sm" class="mr-2 my-1" v-b-tooltip.hover="'Click to remove from list'">
											{{s}}
											<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
										</b-button>
									</div>
								</b-td>
								<b-td>{{getInput('statistics', shortcuts.reportDataHru).description}}</b-td>
								<b-td>{{getInput('statistics', shortcuts.reportDataHru).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('statistics', shortcuts.reportDataHru).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
							<b-tr v-if="show.reportData">
								<b-td class="min">
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" />
									<code>subbasins</code>
								</b-td>
								<b-td>
									<b-typeahead placeholder="Type to search..." :minMatchingChars="2"
												 :data="dynamicOptions.hruSubbasins == null ? [] : dynamicOptions.hruSubbasins"
												 v-model="hruSubbasinsSearch"
												 :serializer="s => s.name"
												 :max-matches="100">
										<template slot="append">
											<b-button @click="addToArray(hruSubbasinsSearch, query.reportData.outputs.hru.subbasins)" variant="secondary" v-b-tooltip.hover="'Add parameter'">
												<font-awesome-icon :icon="['fas', 'plus']" />
											</b-button>
										</template>
									</b-typeahead>
									<div>
										<b-button v-for="(s, i) in query.reportData.outputs.hru.subbasins" :key="i"
												  @click="query.reportData.outputs.hru.subbasins = removeFromArray(s, query.reportData.outputs.hru.subbasins)" variant="light" size="sm" class="mr-2 my-1" v-b-tooltip.hover="'Click to remove from list'">
											{{s}}
											<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
										</b-button>
									</div>
								</b-td>
								<b-td>{{getInput('subbasins', shortcuts.reportDataHru).description}}</b-td>
								<b-td>{{getInput('subbasins', shortcuts.reportDataHru).type}}</b-td>
								<b-td class="text-center"><font-awesome-icon v-if="getInput('subbasins', shortcuts.reportDataHru).required" :icon="['fas', 'check']" /></b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>

				</b-tab>
				<b-tab title="Input JSON">
					<p>
						The submit project API uses the HTTP POST method. The object should be submitted in JSON format.
						The object below shows the JSON generated from the data provided in the Input Builder tab.
					</p>
					<highlightjs language="javascript" :code="queryCode" />
				</b-tab>
				<b-tab title="Output">
					<p>
						Project will take time to process in the background, and output downloads will not be available immediately.
						After submitting your project via HTTP POST, you will receive a URL in the response, which you may then use in an HTTP GET request
						to query the project status and results.
					</p>

					<h3 class="h5 mt-4 mb-3">Response from HTTP POST</h3>
					<b-table-simple class="border-bottom table-valign-m table-form" small>
						<b-thead class="bg-light">
							<b-tr>
								<b-th class="min" style="width:18%">Parameter Name</b-th>
								<b-th>Description</b-th>
								<b-th class="min">Value Type</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr>
								<b-td><code>id</code></b-td>
								<b-td>Unique, auto-assigned identifying number of your request</b-td>
								<b-td>int</b-td>
							</b-tr>
							<b-tr>
								<b-td><code>url</code></b-td>
								<b-td>Full URL to query project status and results via HTTP GET method</b-td>
								<b-td>string</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>

					<h3 class="h5 mt-4 mb-3">Response from HTTP GET using URL from submission response</h3>
					<b-table-simple class="border-bottom table-valign-m table-form" small>
						<b-thead class="bg-light">
							<b-tr>
								<b-th class="min" style="width:18%">Parameter Name</b-th>
								<b-th>Description</b-th>
								<b-th class="min">Value Type</b-th>
							</b-tr>
						</b-thead>
						<b-tbody v-for="d in outputs.definitions" :key="d.name">
							<b-tr>
								<b-td class="min"><code>{{d.name}}</code></b-td>
								<b-td>{{d.description}}</b-td>
								<b-td>{{d.type}}</b-td>
							</b-tr>
							<b-tr v-for="c in d.childParameters" :key="c.name" class="bg-light">
								<b-td class="min"><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="text-muted mr-2" /><code>{{c.name}}</code></b-td>
								<b-td>{{c.description}}</b-td>
								<b-td>{{c.type}}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>

					<api-linker :options-url="outputs.url" options-type="Output"></api-linker>
				</b-tab>
				<b-tab title="Code Examples">
					<b-card no-body class="mb-3 border">
						<b-tabs card>
							<b-tab title="C#">
								<highlightjs language="csharp" :code="csharpCode" />
							</b-tab>
							<b-tab title="Python">
								<highlightjs language="python" :code="pythonCode" />
							</b-tab>
							<b-tab title="JavaScript">
								<highlightjs language="javascript" :code="javascriptCode" />
							</b-tab>
							<b-tab title="R">
								<highlightjs language="r" :code="rCode" />
							</b-tab>
							<b-tab title="Curl">
								<p>
									Save your input JSON to a file and use the --data flag to add it to the command.
								</p>
								<highlightjs :code="curlCode1" />

								<p>
									Query the status of your project using a GET request with the URL return from the POST result object.
								</p>
								<highlightjs :code="curlCode2" />
							</b-tab>
						</b-tabs>
					</b-card>
				</b-tab>
				<b-tab title="Submit Online">
					<p>
						You may submit your project request online below. You will need both your shared and secret (not hashed) API keys.
					</p>

					<error-list :errors="page.submit.errors"></error-list>

					<b-alert variant="success" :show="!isNullOrEmpty(queryResult.url)">
						Your project has been submitted to the queue for processing. You may check the status and output
						<router-link :to="`/docs/projects/${queryResult.id}`" target="_blank">online</router-link>. See the Output tab for more information about reading the results.
					</b-alert>

					<b-form @submit.prevent="submit">
						<b-form-group label="API Key">
							<b-form-input v-model="apiKeys.shared" type="text" required></b-form-input>
						</b-form-group>

						<save-button variant="primary" @saving="page.submit.saving" text="Submit Project"></save-button>
					</b-form>
				</b-tab>
			</b-tabs>
		</b-card>
	</page-authorization-container>
</template>

<script>
	import _ from 'underscore';
	import moment from 'moment';
	import shajs from 'sha.js';

	export default {
		name: 'DocsProjectsSubmit',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					example: {
						errors: [],
						loading: false
					},
					submit: {
						url: 'projects/submit',
						errors: [],
						saving: false
					}
				},
				inputs: {
					url: 'projects/input-definitions',
					definitions: []
				},
				outputs: {
					url: 'projects/output-definitions',
					definitions: []
				},
				query: {
					dataset: null,
					downstreamSubbasin: null,
					upstreamSubbasins: [],
					upstreamDataSource: null,
					setHrus: {
						method: null,
						target: null,
						units: null,
						exemptLanduse: [],
						noAreaRedistribution: []
					},
					weatherDataset: null,
					climateScenario: null,
					startingSimulationDate: null,
					endingSimulationDate: null,
					warmupYears: null,
					outputPrintSetting: null,
					swatVersion: null,
					writeSwatEditorDb: null,
					noOutputProcessing: false,
					noModelRun: false,
					useInWebInterface: false,
					projectName: null,
					reportData: {
						formats: [],
						units: null,
						inputs: {
							includePhysicalProperties: false
						},
						outputs: {
							rch: {
								parameters: [],
								statistics: [],
								subbasins: []
							},
							sub: {
								parameters: [],
								statistics: [],
								subbasins: []
							},
							hru: {
								parameters: [],
								statistics: [],
								subbasins: []
							}
						}
					}
				},
				queryResult: {
					id: null,
					url: null,
					data: {
						status: {
							progress: 0,
							message: null,
							errorStackTrace: null
						},
						output: []
					}
				},
				shortcuts: {
					setHrus: [],
					reportData: [],
					reportDataInputs: [],
					reportDataOutputs: [],
					reportDataRch: [],
					reportDataSub: [],
					reportDataHru: []
				},
				show: {
					setHrus: false,
					reportData: false,
					onlyBasic: true
				},
				dynamicOptions: {
					downstreamSubbasins: [],
					upstreamSubbasins: [],
					exemptLanduse: [],
					noAreaRedistribution: [],
					subSubbasins: [],
					rchSubbasins: [],
					hruSubbasins: []
				},
				downstreamSubbasinSearch: null,
				upstreamSubbasinsSearch: null,
				exemptLanduseSearch: null,
				noAreaRedistributionSearch: null,
				subParametersSearch: null,
				subStatisticsSearch: null,
				subSubbasinsSearch: null,
				rchParametersSearch: null,
				rchStatisticsSearch: null,
				rchSubbasinsSearch: null,
				hruParametersSearch: null,
				hruStatisticsSearch: null,
				hruSubbasinsSearch: null,
				reportFormatsSearch: null,
				notes: {
					dateFormat: "Note: the date picker displays the date according to your local date settings on your computer. But when submitting the JSON object programmatically, make sure the format is yyyy-mm-dd."
				},
				apiKeys: {
					shared: null,
					secret: null
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get',
			downstreamSubbasinSearch: _.debounce(function (query) { this.findDownstreamSubbasins(query) }, 500),
			upstreamSubbasinsSearch: _.debounce(function (query) { this.findUpstreamSubbasins(query) }, 500),
			exemptLanduseSearch: _.debounce(function (query) { this.findExemptLanduse(query) }, 500),
			noAreaRedistributionSearch: _.debounce(function (query) { this.findNoAreaRedistribution(query) }, 500),
			subSubbasinsSearch: _.debounce(function (query) { this.findSubSubbasins(query) }, 500),
			rchSubbasinsSearch: _.debounce(function (query) { this.findRchSubbasins(query) }, 500),
			hruSubbasinsSearch: _.debounce(function (query) { this.findHruSubbasins(query) }, 500)
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				await this.loadApiKeys();
				try {
					const response = await this.$http.get(this.inputs.url);
					this.log(response.data);
					this.inputs.definitions = response.data;

					const response2 = await this.$http.get(this.outputs.url);
					this.log(response2.data);
					this.outputs.definitions = response2.data;

					this.shortcuts.setHrus = this.getInput('setHrus').childParameters;
					this.shortcuts.reportData = this.getInput('reportData').childParameters;
					this.shortcuts.reportDataInputs = this.getInput('inputs', this.shortcuts.reportData).childParameters;
					this.shortcuts.reportDataOutputs = this.getInput('outputs', this.shortcuts.reportData).childParameters;
					this.shortcuts.reportDataRch = this.getInput('rch', this.shortcuts.reportDataOutputs).childParameters;
					this.shortcuts.reportDataSub = this.getInput('sub', this.shortcuts.reportDataOutputs).childParameters;
					this.shortcuts.reportDataHru = this.getInput('hru', this.shortcuts.reportDataOutputs).childParameters;

					this.query.dataset = this.getInput('dataset').defaultValue;
					this.query.setHrus.method = this.getInput('method', this.shortcuts.setHrus).defaultValue;
					this.query.setHrus.target = this.getInput('target', this.shortcuts.setHrus).defaultValue;
					this.query.setHrus.units = this.getInput('units', this.shortcuts.setHrus).defaultValue;
					this.query.setHrus.exemptLanduse = this.getInput('exemptLanduse', this.shortcuts.setHrus).defaultValue;
					this.query.setHrus.noAreaRedistribution = this.getInput('noAreaRedistribution', this.shortcuts.setHrus).defaultValue;
					if (this.weatherDatasetOptions.length > 0) {
						this.query.weatherDataset = this.weatherDatasetOptions[0].value;
						this.setSimulationDates();
					}
					this.query.climateScenario = this.getInput('climateScenario').defaultValue;
					this.query.warmupYears = this.getInput('warmupYears').defaultValue;
					this.query.outputPrintSetting = this.getInput('outputPrintSetting').defaultValue;
					this.query.swatVersion = this.getInput('swatVersion').defaultValue;
					this.query.writeSwatEditorDb = this.getInput('writeSwatEditorDb').defaultValue;
					this.query.reportData.formats = this.getInput('formats', this.shortcuts.reportData).defaultValue;
					this.query.reportData.units = this.getInput('units', this.shortcuts.reportData).defaultValue;

					this.findExemptLanduse('');
					this.findNoAreaRedistribution('');
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async loadApiKeys() {
				if (this.isAuthenticated) {
					try {
						const response = await this.$http.get(`admin/apiclients/find/${this.user.userName}`, this.getTokenHeader());
						this.apiKeys.shared = response.data.sharedKey;
						this.apiKeys.secret = response.data.secretKey;
					} catch (error) {
						this.logError(error);
					}
				}
			},
			async loadExample() {
				this.page.example.errors = [];
				this.page.example.loading = true;

				try {
					const response = await this.$http.get('projects/example');
					this.log(response.data);

					this.show.onlyBasic = true;

					this.downstreamSubbasinSearch = response.data.downstreamSubbasin;
					try { this.$refs.downstreamSubbasinTypeahead.$data.inputValue = response.data.downstreamSubbasin; } catch (e) { this.log(e); }

					this.query.dataset = response.data.dataset;
					this.query.downstreamSubbasin = response.data.downstreamSubbasin;
					this.query.setHrus.method = response.data.setHrus.method;
					this.query.setHrus.target = response.data.setHrus.target;
					this.query.setHrus.units = response.data.setHrus.units;
					this.query.weatherDataset = response.data.weatherDataset;
					this.query.startingSimulationDate = response.data.startingSimulationDate;
					this.query.endingSimulationDate = response.data.endingSimulationDate;
					this.query.warmupYears = response.data.warmupYears;
					this.query.outputPrintSetting = response.data.outputPrintSetting;
					this.query.reportData.formats = response.data.reportData.formats;
					this.query.reportData.units = response.data.reportData.units;
					this.query.reportData.outputs.rch.statistics = response.data.reportData.outputs.rch.statistics;
				} catch (error) {
					this.page.example.errors = this.logError(error);
				}

				this.page.example.loading = false;
			},
			async submit() {
				this.page.submit.errors = [];
				this.page.submit.saving = true;
				this.queryResult.url = null;

				try {
					//let utf8SecretKey = new TextEncoder().encode(this.apiKeys.secret);
					//let secretHash = shajs('sha256').update(utf8SecretKey).digest('base64');

					//let headers = {
					//	headers: { 'Authorization': `amx ${this.apiKeys.shared}:${secretHash}` }
					//};
					let headers = {
						headers: { 'X-API-Key': this.apiKeys.shared }
					};

					const response = await this.$http.post(this.page.submit.url, this.queryObject, headers);
					this.log(response.data);

					this.queryResult.id = response.data.id;
					this.queryResult.url = response.data.url;
				} catch (error) {
					this.page.submit.errors = this.logError(error);
				}

				this.page.submit.saving = false;
			},
			getInput(param, list = null) {
				if (list === null) list = this.inputs.definitions;
				let obj = this.findInList(list, 'name', param);
				if (obj === null) return {};
				return obj;
			},
			async findDownstreamSubbasins(query) {
				try {
					const response = await this.$http.get(this.getSubbasinSearchUrl(query));
					this.dynamicOptions.downstreamSubbasins = response.data != '' && response.data != null ? response.data : [];
					if (!this.isNullOrEmpty(this.downstreamSubbasinSearch)) this.query.downstreamSubbasin = this.downstreamSubbasinSearch;
				} catch (error) {
					this.logError(error);
				}
			},
			async findUpstreamSubbasins(query) {
				try {
					const response = await this.$http.get(this.getSubbasinSearchUrl(query));
					this.dynamicOptions.upstreamSubbasins = response.data != '' && response.data != null ? response.data : [];
				} catch (error) {
					this.logError(error);
				}
			},
			async findSubSubbasins(query) {
				try {
					const response = await this.$http.get(this.getSubbasinSearchUrl(query));
					this.dynamicOptions.subSubbasins = response.data != '' && response.data != null ? response.data : [];
				} catch (error) {
					this.logError(error);
				}
			},
			async findRchSubbasins(query) {
				try {
					const response = await this.$http.get(this.getSubbasinSearchUrl(query));
					this.dynamicOptions.rchSubbasins = response.data != '' && response.data != null ? response.data : [];
				} catch (error) {
					this.logError(error);
				}
			},
			async findHruSubbasins(query) {
				try {
					const response = await this.$http.get(this.getSubbasinSearchUrl(query));
					this.dynamicOptions.hruSubbasins = response.data != '' && response.data != null ? response.data : [];
				} catch (error) {
					this.logError(error);
				}
			},
			getSubbasinSearchUrl(query) {
				return `query/subbasins?dataset=${this.query.dataset}&startsWith=${query}`;
			},
			addUpstreamSubbasin() {
				if (!this.isNullOrEmpty(this.upstreamSubbasinsSearch)) this.query.upstreamSubbasins.push(this.upstreamSubbasinsSearch);
			},
			removeUpstreamSubbasin(s) {
				this.query.upstreamSubbasins = this.query.upstreamSubbasins.filter(function (el) { return el !== s; });
			},
			async findExemptLanduse(query) {
				try {
					const response = await this.$http.get(this.getLanduseSearchUrl(query));
					this.dynamicOptions.exemptLanduse = response.data != '' && response.data != null ? response.data : [];
				} catch (error) {
					this.logError(error);
				}
			},
			async findNoAreaRedistribution(query) {
				try {
					const response = await this.$http.get(this.getLanduseSearchUrl(query));
					this.dynamicOptions.noAreaRedistribution = response.data != '' && response.data != null ? response.data : [];
				} catch (error) {
					this.logError(error);
				}
			},
			getLanduseSearchUrl(query) {
				return `query/landuse?dataset=${this.query.dataset}&startsWith=${query}`;
			},
			addExemptLanduse() {
				if (!this.isNullOrEmpty(this.exemptLanduseSearch)) this.query.setHrus.exemptLanduse.push(this.exemptLanduseSearch);
			},
			removeExemptLanduse(s) {
				this.query.setHrus.exemptLanduse = this.query.setHrus.exemptLanduse.filter(function (el) { return el !== s; });
			},
			addNoAreaRedistribution() {
				if (!this.isNullOrEmpty(this.noAreaRedistributionSearch)) this.query.setHrus.noAreaRedistribution.push(this.noAreaRedistributionSearch);
			},
			removeNoAreaRedistribution(s) {
				this.query.setHrus.noAreaRedistribution = this.query.setHrus.noAreaRedistribution.filter(function (el) { return el !== s; });
			},
			setSimulationDates() {
				if (this.weatherDatasetOptions.length > 0 && !this.isNullOrEmpty(this.query.weatherDataset)) {
					let obj = this.findInList(this.weatherDatasetOptions, 'value', this.query.weatherDataset);
					let upObj = this.findInList(this.upstreamDataSourceOptions, 'value', this.query.upstreamDataSource);
					if (obj !== null) {
						if (upObj !== null) {
							this.query.startingSimulationDate = moment.max([moment(obj.startDate), moment(upObj.startDate)]).format('YYYY-MM-DD');
							this.query.endingSimulationDate = moment.min([moment(obj.endDate), moment(upObj.endDate)]).format('YYYY-MM-DD');
						} else {
							this.query.startingSimulationDate = obj.startDate;
							this.query.endingSimulationDate = obj.endDate;
						}
					}
				}
			},
			addToArray(value, array) {
				if (!this.isNullOrEmpty(value)) array.push(value);
			},
			removeFromArray(value, array) {
				return array.filter(function (el) { return el !== value; });
			}
		},
		computed: {
			queryCode() {
				return JSON.stringify(this.queryObject, null, 4);
			},
			queryObject() {
				if (!this.show.onlyBasic) return this.query;

				let obj = {
					dataset: this.query.dataset,
					downstreamSubbasin: this.query.downstreamSubbasin,
					setHrus: {
						method: this.query.setHrus.method,
						target: this.query.setHrus.target,
						units: this.query.setHrus.units
					},
					weatherDataset: this.query.weatherDataset,
					startingSimulationDate: this.query.startingSimulationDate,
					endingSimulationDate: this.query.endingSimulationDate,
					warmupYears: this.query.warmupYears,
					outputPrintSetting: this.query.outputPrintSetting,
					reportData: {
						formats: this.query.reportData.formats,
						units: this.query.reportData.units,
						outputs: {}
					}
				};

				if (this.query.dataset === 'HUC14' && this.query.disaggregateComids) {
					obj.disaggregateComids = true;
				}

				if (this.query.reportData.inputs.includePhysicalProperties) {
					obj.reportData.inputs = {
						includePhysicalProperties: true
					};
				}

				let rch = {};
				let hasRch = false;
				if (this.query.reportData.outputs.rch.parameters.length > 0) { hasRch = true; rch.parameters = this.query.reportData.outputs.rch.parameters; }
				if (this.query.reportData.outputs.rch.statistics.length > 0) { hasRch = true; rch.statistics = this.query.reportData.outputs.rch.statistics; }
				if (this.query.reportData.outputs.rch.subbasins.length > 0) { hasRch = true; rch.subbasins = this.query.reportData.outputs.rch.subbasins; }
				if (hasRch) {
					obj.reportData.outputs.rch = rch;
				}

				let sub = {};
				let hasSub = false;
				if (this.query.reportData.outputs.sub.parameters.length > 0) { hasSub = true; sub.parameters = this.query.reportData.outputs.sub.parameters; }
				if (this.query.reportData.outputs.sub.statistics.length > 0) { hasSub = true; sub.statistics = this.query.reportData.outputs.sub.statistics; }
				if (this.query.reportData.outputs.sub.subbasins.length > 0) { hasSub = true; sub.subbasins = this.query.reportData.outputs.sub.subbasins; }
				if (hasSub) {
					obj.reportData.outputs.sub = sub;
				}

				let hru = {};
				let hasHru = false;
				if (this.query.reportData.outputs.hru.parameters.length > 0) { hasHru = true; hru.parameters = this.query.reportData.outputs.hru.parameters; }
				if (this.query.reportData.outputs.hru.statistics.length > 0) { hasHru = true; hru.statistics = this.query.reportData.outputs.hru.statistics; }
				if (this.query.reportData.outputs.hru.subbasins.length > 0) { hasHru = true; hru.subbasins = this.query.reportData.outputs.hru.subbasins; }
				if (hasHru) {
					obj.reportData.outputs.hru = hru;
				}

				return obj;
			},
			weatherDatasetOptions() {
				let byDataset = this.getInput('weatherDataset').options;
				if (this.isNullOrEmpty(byDataset)) return [];
				let obj = this.findInList(byDataset, 'dataset', this.query.dataset);
				if (obj === null) return [];
				return obj.weatherDatasets;
			},
			selectedWeatherDataset() {
				return this.findInList(this.weatherDatasetOptions, 'value', this.query.weatherDataset);
			},
			upstreamDataSourceOptions() {
				let byDataset = this.getInput('upstreamDataSource').options;
				if (this.isNullOrEmpty(byDataset)) return [];
				let obj = this.findInList(byDataset, 'dataset', this.query.dataset);
				if (obj === null) return [];
				return obj.upstreamDataSources;
			},
			selectedUpstreamDataSource() {
				return this.findInList(this.upstreamDataSourceOptions, 'value', this.query.upstreamDataSource);
			},
			csharpCode() {
				let code = `using Newtonsoft.Json;
using System.Text;

//Data to be set before running the program:
string apiKey = "YOUR_API_KEY;"
string baseUrl = "${this.siteText.appUrl}";
string savePath = "C:/path_to_save_files"; //A directory to save all your output files
var pollInterval = TimeSpan.FromSeconds(10); //Define how frequently to check the project's run status

var inputData = new
{
	dataset = "HUC8",
	downstreamSubbasin = "07100009",
	setHrus = new
	{
		method = "area",
		target = 2,
		units = "km2"
	},
	weatherDataset = "PRISM",
	startingSimulationDate = "1981-01-01",
	endingSimulationDate = "1985-12-31",
	warmupYears = 2,
	outputPrintSetting = "daily",
	reportData = new {
		formats = new List<string> { "csv", "netcdf" },
        units = "metric",
        outputs = new {
			rch = new {
				statistics = new List<string> { "daily_avg" }
            }
		}
	}
};

using var client = new HttpClient();
var postMessage = new HttpRequestMessage(HttpMethod.Post, $"{baseUrl}/${this.page.submit.url}");
postMessage.Headers.Add("X-API-Key", apiKey);
postMessage.Content = new StringContent(JsonConvert.SerializeObject(inputData), Encoding.UTF8, "application/json");
var postResult = await client.SendAsync(postMessage);

if (!postResult.IsSuccessStatusCode)
{
	Console.WriteLine($"Error sending API request: {postResult.StatusCode}, {postResult.ReasonPhrase}");
	return 1;
}

var submissionStr = await postResult.Content.ReadAsStringAsync();
var submissionResult = JsonConvert.DeserializeObject<Dictionary<string, dynamic>>(submissionStr);
if (submissionResult == null || !submissionResult.ContainsKey("url"))
{
	Console.WriteLine($"Unexpected API POST response: {submissionStr}");
	return 1;
}

Console.WriteLine($"Project ID {submissionResult["id"]} created");

var timer = new PeriodicTimer(pollInterval);
var project = await GetStatus(client, submissionResult["url"], apiKey);

while (await timer.WaitForNextTickAsync())
{
	project = await GetStatus(client, submissionResult["url"], apiKey);

	if (project.Status.Progress >= 100) 
	{
		Console.WriteLine();
		if (!string.IsNullOrWhiteSpace(project.Status.ErrorStackTrace))
		{
			Console.WriteLine($"Error stack trace: {project.Status.ErrorStackTrace}");
		}
		timer.Dispose();
	}
}

foreach (var file in project.Output)
{
	Console.WriteLine($"Retrieving and saving {file.Name} ({file.Format})");
	string fileName = file.Url.Split('/').Last();
	var message = new HttpRequestMessage(HttpMethod.Get, file.Url);
	var result = await client.SendAsync(message);

	using var stream = await result.Content.ReadAsStreamAsync();
	using var fileStream = new FileStream(Path.Combine(savePath, fileName), FileMode.Create);
	await stream.CopyToAsync(fileStream);
}

return 0;

static async Task<ProjectResult> GetStatus(HttpClient client, string url, string apiKey)
{
	var message = new HttpRequestMessage(HttpMethod.Get, url);
	message.Headers.Add("X-API-Key", apiKey);
	var result = await client.SendAsync(message);

	var str = await result.Content.ReadAsStringAsync();
	var project = JsonConvert.DeserializeObject<ProjectResult>(str);

	var statusText = $"{project.Status.Progress}% - {project.Status.Message}";
	var consoleText = statusText + new string(' ', Console.WindowWidth - statusText.Length);
	int currentLineCursor = Console.CursorTop;
	Console.Write($"\\r{consoleText}");
	if (project.Status.Progress < 100) Console.SetCursorPosition(0, currentLineCursor);

	return project;
}

class ProjectResult
{
	public ProjectStatus Status { get; set; }
	public List<ProjectFile> Output { get; set; }
}

class ProjectStatus
{
	public int Progress { get; set; }
	public string Message { get; set; }
	public string ErrorStackTrace { get; set; }
}

class ProjectFile
{
	public string Name { get; set; }
	public string Url { get; set; }
	public string Format { get; set; }
}`;

				return code;
			},
			javascriptCode() {
				let code = `import axios from 'axios';

let headers = {
	headers: { 'X-API-Key': 'YOUR_API_KEY' }
};

//The following input data is a snippet for demonstration purposes.
//Use the Input Builder tab, Input JSON tab, and/or Input Definitions API for help constructing your input object.
let inputData = {
	dataset: 'HUC8',
	downstreamSubbasin: '07100009',
	setHrus: {
		method: 'area',
		target: 2,
		units: 'km2'
	}
};

let submissionResult = null;
try {
	const postResponse = await axios.post('${this.siteText.appUrl}/${this.page.submit.url}', inputData, headers);
	submissionResult = postResponse.data;
} catch (error) {
	console.error(error);
}

//Your project status URL is now stored in your submissionResult.
//It is up to you how to check the progress. The following example shows checking on an interval every 60 seconds.
//As an alternative to the polling the data example below, we do make use of Microsoft SignalR on the server to push updates to clients.
//This is more advanced than this example, so please contact the development team if needed.

if (submissionResult !== null) {
	let isComplete = false;
	let projectStatus = null;
	let intervalTimer = setInterval(() => {
		projectStatus = await checkStatus();
	}, 60000);

	if (projectStatus != null) {
		if (projectStatus.status.errorStackTrace !== null) {
			//There was an error running your project.
			console.error(projectStatus.status.errorStackTrace);
			clearInterval(intervalTimer);
		}
		else if (projectStatus.status.progress >= 100) {
			for (let file of projectStatus.output) {
				//Choose what to do with files here
				let name = file.name;
				let url = file.url;
			}
			clearInterval(intervalTimer);
		}
	}
}

async function checkStatus() {
	try {
		const getResponse = await axios.get(submissionResult.url, headers);
		return getResponse.data;
	} catch (error) {
		console.error(error);
	}
	return null;
}`;

				return code;
			},
			pythonCode() {
				let host = this.siteText.appUrl.replace('http://', '').replace('https://', '');
				let protocolType = this.siteText.appUrl.startsWith('https://') ? 'HTTPSConnection' : 'HTTPConnection';
				let code = `import http.client
import json
import urllib.request
import os
from time import time, sleep

#Data to be set before running the program:
connection = http.client.${protocolType}('${host}')
apiKey = 'YOUR_API_KEY'
savePath = 'C:/path_to_save_files' #A directory to save all your output files
pollInterval = 10 #Define how frequently to check the project's run status (seconds)

inputData = {
	'dataset': 'HUC8',
	'downstreamSubbasin': '07100009',
	'setHrus': {
		'method': 'area',
		'target': 2,
		'units': 'km2'
	},
	'weatherDataset': 'PRISM',
    'startingSimulationDate': '1981-01-01',
    'endingSimulationDate': '1985-12-31',
    'warmupYears': 2,
    'outputPrintSetting': 'daily',
    'reportData': {
        'formats': [ 'csv', 'netcdf' ],
        'units': 'metric',
        'outputs': {
            'rch': {
                'statistics': [ 'daily_avg' ]
            }
        }
    }
}

headers = { 'X-API-Key': apiKey, 'Content-type': 'application/json' }


def getStatus(id, spaces):
	connection.request('GET', '/projects/{}'.format(id), None, headers)
	response = connection.getresponse()
	project = json.loads(response.read().decode())

	msg = '{}% - {}'.format(project['status']['progress'], project['status']['message'])
	print(msg + ' ' * abs(spaces - len(msg)), end='\\r', flush=True)

	return project, len(msg)

connection.request('POST', '/projects/submit', json.dumps(inputData), headers)
postResponse = connection.getresponse()
submissionResult = json.loads(postResponse.read().decode())

#Your project status ID and URL are now stored in your submissionResult.
#Because the Python http.client separates the host name, we recommend using the id property as shown below.
print('Project ID {} created'.format(submissionResult['id']))
starttime = time()
spaces = 0
while True:
	project, spaces = getStatus(submissionResult['id'], spaces)
	if project['status']['progress'] >= 100:
		print()
		break
	sleep(pollInterval - ((time() - starttime) % pollInterval))

error = project['status']['errorStackTrace']
if error is not None:
	print('Error stack trace: {}'.format(error))

for file in project['output']:
	print('Retrieving and saving {} ({})'.format(file['name'], file['format']))
	filename = file['url'].split('/')[-1]
	urllib.request.urlretrieve(file['url'], os.path.join(savePath, filename))`;

				return code;
			},
			curlCode1() {
				let code = `curl --request POST "${this.siteText.appUrl}/${this.page.submit.url}" --header "X-API-Key: YOUR_API_KEY" --data @inputData.json`;

				return code;
			},
			curlCode2() {
				let code = `curl --request GET "URL_RETURNED_FROM_POST" --header "X-API-Key: YOUR_API_KEY"`;

				return code;
			},
			rCode() {
				let code = `library(httr2)

headers <- list("Content-Type" = "application/json", "X-API-Key" = "YOUR_API_KEY")

input_data <- list(dataset = "HUC8",
                   downstreamSubbasin = "07100009",
                   setHrus = list(
                     method = "area",
                     target = 2,
                     units = "km2"
                   ),
                   weatherDataset = "PRISM",
                   startingSimulationDate = "1981-01-01",
                   endingSimulationDate = "1985-12-31",
                   warmupYears = 2,
                   outputPrintSetting = "daily",
                   reportData = list(
                     formats = list("csv"),
                     units = "metric",
                     outputs = list(rch = list(statistics = list("daily_avg")))
                   ))

raw_resp <- request("${this.siteText.appUrl}/${this.page.submit.url}") %>% 
  req_headers(!!!headers) %>% 
  req_body_json(input_data) %>% 
  req_perform(verbosity = 3)  %>% 
  resp_raw()`;

				return code;
			}
		}
	}
</script>

<template>
	<page-authorization-container :page="page" no-auth>
		<h2 class="mb-3">Cancel Project</h2>

		<p>
			Cancel a project run by submitting an HTTP PATCH method with the API request ID number in the URL.
		</p>
		<p>
			Your API request ID number is returned in the body of the HTTP POST method when you submitted the project.
		</p>

		<h3 class="mt-5 mb-3">Code Examples</h3>

		<p>
			In the following examples, a fake API request ID of 123 is used.
		</p>

		<b-card no-body class="mb-3 border">
			<b-tabs card>
				<b-tab title="C#">
					<highlightjs language="csharp" :code="csharpCode" />
				</b-tab>
				<b-tab title="Python">
					<highlightjs language="python" :code="pythonCode" />
				</b-tab>
				<b-tab title="JavaScript">
					<highlightjs language="javascript" :code="javascriptCode" />
				</b-tab>
				<b-tab title="Curl">
					<highlightjs :code="curlCode" />
				</b-tab>
			</b-tabs>
		</b-card>
	</page-authorization-container>
</template>

<script>
	export default {
		name: 'DocsProjectCancel',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				apiKeys: {
					shared: null,
					secret: null
				}
			}
		},
		async created() {
			await this.loadApiKeys();
		},
		methods: {
			async loadApiKeys() {
				if (this.isAuthenticated) {
					this.page.project.loading = true;
					try {
						const response = await this.$http.get(`admin/apiclients/find/${this.user.userName}`, this.getTokenHeader());
						this.apiKeys.shared = response.data.sharedKey;
						this.apiKeys.secret = response.data.secretKey;
					} catch (error) {
						this.logError(error);
					}
					this.page.project.loading = false;
				}
			}
		},
		computed: {
			queryUrl() {
				return `projects/cancel/123`;
			},
			csharpCode() {
				let code = `string url = "${this.siteText.appUrl}/${this.queryUrl}";
string apiKey = "YOUR_API_KEY;"

using var client = new HttpClient();
var message = new HttpRequestMessage(HttpMethod.Patch, url);
message.Headers.Add("X-API-Key", apiKey);
var result = await client.SendAsync(message);`;

				return code;
			},
			javascriptCode() {
				let code = `import axios from 'axios';

let headers = {
	headers: { 'X-API-Key': 'YOUR_API_KEY' }
};

const url = '${this.siteText.appUrl}/${this.queryUrl}';

try {
	await axios.patch(url, {}, headers);
} catch (error) {
	console.error(error);
}`;

				return code;
			},
			pythonCode() {
				let host = this.siteText.appUrl.replace('http://', '').replace('https://', '')
				let code = `import http.client

connection = http.client.HTTPSConnection('${host}')
headers = { 'X-API-Key': 'YOUR_API_KEY', 'Content-type': 'application/json' }

connection.request('PATCH', '${this.queryUrl}', None, headers)
response = connection.getresponse()`;

				return code;
			},
			curlCode() {
				let code = `curl --request PATCH "${this.siteText.appUrl}/${this.queryUrl}" --header "X-API-Key: YOUR_API_KEY"`;

				return code;
			}
		}
	}
</script>

<template>
	<div>
		<div class="d-flex">
			<div v-if="!isNullOrEmpty(url)" class="flex-fill">
				<b-input-group prepend="API Url">
					<b-form-input type="text" v-model="queryUrl" ref="queryUrl" v-on:focus="$event.target.select()"></b-form-input>
					<b-input-group-append>
						<b-button variant="outline-primary" title="Copy" @click="copy('queryUrl')"><font-awesome-icon :icon="['far', 'copy']" fixed-width class="mx-3" /></b-button>
						<b-button variant="primary" :href="queryUrl" target="_blank">Run in Browser <font-awesome-icon :icon="['fas', 'play']" fixed-width class="ml-1" /></b-button>
					</b-input-group-append>
				</b-input-group>
			</div>
			<div v-if="!isNullOrEmpty(optionsUrl)">
				<b-button variant="secondary" @click="options.show = true" :class="isNullOrEmpty(url) ? null : 'ml-1'">{{optionsType}} Definitions API</b-button>
			</div>
		</div>

		<b-modal v-model="options.show" size="lg" :title="`Get ${optionsType} Definitions API`">
			<p>
				Use the following URL to get the {{optionsType.toLowerCase()}} parameter definitions for this API method programmatically.
			</p>
			<div>
				<b-input-group prepend="API Url">
					<b-form-input type="text" v-model="queryOptionsUrl" ref="queryOptionsUrl" v-on:focus="$event.target.select()"></b-form-input>
					<b-input-group-append>
						<b-button variant="outline-primary" title="Copy" @click="copy('queryOptionsUrl')"><font-awesome-icon :icon="['far', 'copy']" fixed-width class="mx-3" /></b-button>
						<b-button variant="primary" :href="queryOptionsUrl" target="_blank">Run in Browser <font-awesome-icon :icon="['fas', 'play']" fixed-width class="ml-1" /></b-button>
					</b-input-group-append>
				</b-input-group>
			</div>

			<div slot="modal-footer">
				<b-button type="button" variant="secondary" @click="options.show = false" class="ml-1">Close</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	export default {
		name: 'ApiLinker',
		props: {
			url: {
				type: String,
				default: ''
			},
			optionsUrl: {
				type: String,
				default: ''
			},
			optionsType: {
				type: String,
				default: 'Input'
			},
			method: {
				type: String,
				default: 'GET'
			}
		},
		data() {
			return {
				options: {
					show: false
				}
			}
		},
		methods: {
			copy(refId) {
				this.$refs[refId].focus();
				document.execCommand('copy');
			}
		},
		computed: {
			queryUrl() {
				return `${this.siteText.appUrl}/${this.url}`;
			},
			queryOptionsUrl() {
				return `${this.siteText.appUrl}/${this.optionsUrl}`;
			}
		}
	}
</script>

import Vue from 'vue';
import { mapGetters } from 'vuex';
import moment from 'moment';

Vue.mixin({
	data() {
		return {
			publicPath: process.env.BASE_URL,
			contactEmail: 'eco.web@tamu.edu',
			roleNames: {
				pending: 'Pending',
				registered: 'Registered',
				admin: 'Admin',
				partialAdmin: 'PartialAdmin'
			},
			globals: {
				passwordRequirements: 'Minimum 10 characters with at least one number, upper and lowercase letter, and special character'
			}
		}
	},
	watch: {
		'$route'() {
			var storedToken = localStorage.getItem('auth_token');
			if (!storedToken || (storedToken !== this.token)) this.$store.commit('logout');
		}
	},
	computed: {
		...mapGetters(['isAuthenticated', 'user', 'roles', 'token', 'rememberMeToken', 'siteText']),
		localStorageToken() {
			return localStorage.getItem('auth_token');
		},
		getValidState() {
			return (prop) => {
				return prop.$dirty ? !prop.$error : null;
			}
		},
		requiredFeedback() {
			return (prop) => {
				if (!prop.required) return 'Required';
			}
		}
	},
	methods: {
		getTokenHeader() {
			return {
				headers: { 'Authorization': 'Bearer ' + localStorage.getItem('auth_token') }
			};
		},
		getTokenHeaderPart() {
			return { 'Authorization': 'Bearer ' + localStorage.getItem('auth_token') };
		},
		isAuthorized(role) {
			return this.isAuthenticated && this.user.emailConfirmed && this.roles.includes(role);
		},
		logout(redirect = true) {
			localStorage.removeItem('auth_token');
			this.$store.commit('logout');
			if (redirect)
				this.$router.push('/').catch(err => { this.log(err) });
		},
		isApiUnauthorized(error) {
			return error.response && (error.response.status === 401 || error.response.status === 403);
		},
		isDevMode() {
			return process.env.NODE_ENV === 'development';
		},
		isNullOrEmpty(value) {
			return value === undefined || value === null || value === '';
		},
		capitalizeFirstLette(s) {
			return s.charAt(0).toUpperCase() + s.slice(1);
		},
		lowercaseFirstLetter(s) {
			return s.charAt(0).toLowerCase() + s.slice(1);
		},
		toValidFileName(s) {
			if (this.isNullOrEmpty(s)) return s;
			let val = s.replace(/[^A-Za-z0-9_\- ]/g, '');
			val = val.replace(/ /g, '-');
			val = val.replace(/---/g, '-');
			return val.toLowerCase();
		},
		numberWithCommas(x) {
			var parts = x.toString().split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return parts.join(".");
		},
		numberFixed(num, decimals) {
			if (this.isNullOrEmpty(num)) return num;
			return num.toFixed(decimals);
		},
		numberFormat(value, decimals = 1, units = '') {
			return this.numberWithCommas(Number(value).toFixed(decimals)) + units;
		},
		toDate(value, format = 'llll') {
			if (value) {
				return moment(String(value)).format(format);
			}
		},
		log(message) {
			if (this.isDevMode()) {
				console.log(message);
			}
		},
		logError(error, defaultMessage = undefined) {
			var messages = [];
			if (defaultMessage) {
				messages.push(defaultMessage);
			}
			console.log(error);

			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.log(error.response.data);
				//console.log(error.response.status);
				//console.log(error.response.headers);

				if (error.response.status === 401 || error.response.status === 403) {
					messages.push('Invalid credentials. Either your user name or password is incorrect, or you do not have permission to view this page.');
				} else if (error.response.status === 404) {
					messages.push('Requested data not found. Please check the URL in your browser and make sure you have the correct ID numbers if any.');
				} else if (error.response.data && error.response.data.errors) {
					let errors = error.response.data.errors;
					for (let key in errors) {
						for (let j = 0; j < errors[key].length; j++) {
							messages.push(errors[key][j]);
						}
					}
				} else if (error.response.data) {
					if (typeof error.response.data === 'string' || error.response.data instanceof String)
						messages.push(error.response.data);
					else if (typeof error.response.data === 'object') {
						messages.push('We encountered the following errors processing your request:');
						let errors = error.response.data;
						for (let key in errors) {
							for (let j = 0; j < errors[key].length; j++) {
								messages.push(`(${key}) ${errors[key][j]}`);
							}
						}
					} else
						messages.push('There was an error processing your request.');
				}

				if (error.response.data.stackTrace) {
					console.log(error.response.data.stackTrace);
				}
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.log(error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', error.message);
			}
			//console.log(error.config);

			return messages;
		},
		errorContainsKey(error, key) {
			return error.response && error.response.data && error.response.data.errors && Object.prototype.hasOwnProperty.call(error.response.data.errors, key); //error.response.data.errors.hasOwnProperty(key);
		},
		rand() {
			return Math.random().toString(36).substr(2); // remove `0.`
		},
		generateRand() {
			return this.rand() + this.rand();
		},
		setRememberMe(userName) {
			let storedRememberDeviceString = localStorage.getItem('remember_device');
			let storedRememberDevice = [];
			if (storedRememberDeviceString !== undefined && storedRememberDeviceString !== null) {
				storedRememberDevice = JSON.parse(storedRememberDeviceString);
				let remembered = storedRememberDevice.filter(function (el) { return el.user !== userName; });
				storedRememberDevice = remembered;
			}

			let token = this.generateRand();

			storedRememberDevice.push({
				user: userName,
				timestamp: moment().format(),
				token: token
			});

			localStorage.setItem('remember_device', JSON.stringify(storedRememberDevice));

			this.$store.commit('storeRememberMeToken', {
				token: token
			});

			this.log(storedRememberDevice);
			this.log(token);

			return token;
		},
		getRememberMe(userName) {
			let storedRememberDeviceString = localStorage.getItem('remember_device');
			this.log(storedRememberDeviceString);
			if (storedRememberDeviceString === undefined || storedRememberDeviceString === null) {
				return null;
			}

			let storedRememberDevice = JSON.parse(storedRememberDeviceString);
			let remembered = storedRememberDevice.filter(function (el) { return el.user === userName; });
			if (remembered === null || remembered.length < 1) {
				return null;
			}

			return remembered[0].token;
		},
		joinList(array, sep = ', ') {
			if (!array || array.length < 1) return null;
			return array.join(sep);
		},
		findInList(list, param, value) {
			if (list === null || list === undefined || list.length < 1) return null;
			var filtered = list.filter(function (el) { return el[param] === value; });
			if (filtered.length > 0) return filtered[0];
			return null;
		}
	}
})

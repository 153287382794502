<template>
	<page-authorization-container :page="page" require-admin>
		<h2 class="mb-3">API Client</h2>

		<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
			<b-tab title="Profile">
				<b-toast id="changes-saved" variant="success" solid title="Changes saved" toaster="b-toaster-top-center">
					Your changes have been saved.
				</b-toast>
				<error-list :errors="page.saveErrors"></error-list>

				<b-form>
					<b-row>
						<b-col md>
							<b-form-group label="API key type">
								<b-form-select v-model="data.name" :options="options.names" required>
									<template #first>
										<b-form-select-option :value="null" disabled>-- Please select a key type --</b-form-select-option>
									</template>
								</b-form-select>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="User name">
								<b-form-input v-model="data.userName" type="text"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>

					<div class="my-3">
						<b-form-checkbox v-model="data.disabled">Disable? Check to disable these API keys.</b-form-checkbox>
					</div>
					<div class="my-3">
						<b-form-checkbox v-model="generateNewKeys">Generate new API keys? This will deactivate the current keys immediately.</b-form-checkbox>
					</div>

					<b-table-simple class="border-bottom">
						<b-tr>
							<b-th class="min">Shared Key</b-th>
							<b-td><code>{{data.sharedKey}}</code></b-td>
						</b-tr>
						<b-tr>
							<b-th class="min">Secret Key (not currently used)</b-th>
							<b-td><code>{{data.secretKey}}</code></b-td>
						</b-tr>
					</b-table-simple>
				</b-form>

			</b-tab>
			<b-tab title="Test Connectivity">
				<p>
					Test a call to the API using the client's keys.
				</p>

				<p>
					<save-button :saving="page.saving" @click.native="connectionTest" class="mr-2" text="Perform GET request via C#" />
					<save-button :saving="page.saving" @click.native="connectionJsTest" class="mr-2" text="Perform GET request via JavaScript" />
				</p>

				<div v-if="testResponse.success">
					Response status: {{testResponse.data.status}}<br />
					API key type: {{testResponse.data.name}}<br />
					API user name: {{testResponse.data.userName}}
				</div>

				<error-list :errors="page.saveErrors"></error-list>
			</b-tab>
			<b-tab title="Requests">

			</b-tab>
		</b-tabs>

		<fixed-action-bar :cols-lg="10" :offset-lg="2">
			<save-button v-if="page.tabIndex == 0" :saving="page.saving" @click.native="save" class="mr-2" />
			<back-button class="btn btn-secondary mr-2" />
			<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
		</fixed-action-bar>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete this API client? All associated API requests will also be deleted.
				This action is permanent and cannot be undone.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</page-authorization-container>
</template>

<script>
	//import shajs from 'sha.js';

	export default {
		name: 'AdminClientsView',
		data() {
			return {
				id: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: [],
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				data: {},
				options: {
					names: []
				},
				generateNewKeys: false,
				testResponse: {
					success: false,
					data: {}
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`admin/apiclients/${this.$route.params.id}`, this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;

					const response2 = await this.$http.get('admin/apiclients/options', this.getTokenHeader());
					this.log(response2.data);
					this.options = response2.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					let data = {
						name: this.data.name,
						userName: this.data.userName,
						disabled: this.data.disabled,
						generateNewKeys: this.generateNewKeys
					};

					const response = await this.$http.put(`admin/apiclients/put/${this.$route.params.id}`, data, this.getTokenHeader());
					this.log(response.data);
					this.$bvToast.show('changes-saved');
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`admin/apiclients/delete/${this.$route.params.id}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: 'AdminClients' });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			async connectionTest() {
				this.page.saveErrors = [];
				this.page.saving = true;
				this.testResponse.data = {};
				this.testResponse.success = false;

				try {
					const response = await this.$http.get(`admin/apiclients/connecttest/${this.$route.params.id}`, this.getTokenHeader());
					this.log(response.data);
					this.testResponse.data = response.data;
					this.testResponse.success = true;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			async connectionJsTest() {
				this.page.saveErrors = [];
				this.page.saving = true;
				this.testResponse.data = {};
				this.testResponse.success = false;

				try {
					let headers = {
						headers: { 'X-API-Key': `${this.data.sharedKey}` }
					};

					const response = await this.$http.get(`test/clientget`, headers);
					this.log(response.data);
					this.testResponse.data = response.data;
					this.testResponse.success = true;
				} catch (error) {
					this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			/*async connectionJsTest() {
				this.page.saveErrors = [];
				this.page.saving = true;
				this.testResponse.data = {};
				this.testResponse.success = false;

				try {
					let utf8SecretKey = new TextEncoder().encode(this.data.secretKey);
					let secretHash = shajs('sha256').update(utf8SecretKey).digest('base64');

					let headers = {
						headers: { 'Authorization': `amx ${this.data.sharedKey}:${secretHash}` }
					};

					const response = await this.$http.get(`test/clientget`, headers);
					this.log(response.data);
					this.testResponse.data = response.data;
					this.testResponse.success = true;
				} catch (error) {
					this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			}*/
		}
	}
</script>

<template>
	<b-container fluid class="p-0">
		<b-row no-gutters>
			<b-col md="3" lg="2" order="2" order-md="1" class="fixed-height bg-light">
				<div class="px-3 pt-md-4">
					<h2 class="h6">Getting Started</h2>
					<b-nav vertical class="dash-items-nav nav-sm mb-4">
						<b-nav-item to="/" :class="$route.name == 'Home' ? '' : 'no-router-link-active'">Basic API Usage</b-nav-item>
					</b-nav>

					<h2 class="h6">Running Projects</h2>
					<b-nav vertical class="dash-items-nav nav-sm mb-4">
						<b-nav-item to="/docs/projects/submit">Submit a Project</b-nav-item>
						<b-nav-item to="/docs/projects/cancel">Cancel a Project</b-nav-item>
					</b-nav>

					<h2 class="h6">Querying Data</h2>
					<b-nav vertical class="dash-items-nav nav-sm mb-4">
						<b-nav-item to="/docs/query/subbasins">Subbasins</b-nav-item>
					</b-nav>
				</div>
			</b-col>
			<b-col md="9" lg="10" order="1" order-md="2" class="fixed-height shadow-sm no-shadow-sm" style="z-index:500">
				<b-breadcrumb  v-if="$route.name !== 'Home'" :items="breadCrumbs" class="no-curves bg-white px-md-4 mb-0"></b-breadcrumb>
				<div class="container-fluid px-md-4 pb-4">
					<div v-if="$route.name == 'Home'" class="pt-4">
						<p class="lead">
							The purpose of the {{siteText.appName}} API is to provide programmatic access to data and processes outside of the
							main <a :href="siteText.mainWebUrl" target="_blank">{{siteText.appName}} web interface</a>. This site will document all methods and parameters available through the API,
							as well as give basic code examples. The API and documentation are a work in progress.
						</p>

						<p class="lead">
							The {{siteText.appName}} API is a <a href="https://restfulapi.net/" target="_blank">REST API</a> and users will access its endpoints via standard HTTP methods.
							The default format for data submitted and received is JSON. The user will have the option to receive data in CSV, Sqlite, zip/7zip, and NetCDF
							for some endpoints.
						</p>

						<hr class="my-4" />

						<h3 class="mb-3">API Access</h3>
						<p>
							To make use of API functionality such as setting up and running models, you will need to contact the development team for
							an API key. The keys will be sent in the header of the HTTP method. Sample code in C#, Python, and JavaScript are shown below:
						</p>

						<b-card no-body class="mb-3 border">
							<b-tabs card>
								<b-tab title="C#">
									<highlightjs language="csharp" :code="csharpCode" />
								</b-tab>
								<b-tab title="Python">
									<highlightjs language="python" :code="pythonCode" />
								</b-tab>
								<b-tab title="JavaScript">
									<highlightjs language="javascript" :code="javascriptCode" />
								</b-tab>
								<b-tab title="Curl">
									<highlightjs :code="curlCode" />
								</b-tab>
							</b-tabs>
						</b-card>

						<p>
							Currently, some data queries are available for public use without an API key. This is still under development.
						</p>

						<h3 class="mb-3">Non-programmatic Access</h3>

						<p>
							To make use of the API without coding, you may use programs such as <a href="https://www.postman.com/" target="_blank">Postman</a> or
							<a href="https://curl.se/" target="_blank">Curl</a>.
							In addition, this website will allow you to perform many of the API methods.
						</p>

						<h3 class="mb-3">Viewing JSON in the Browser</h3>

						<p>
							It can be difficult to view raw JSON data in the web browser. While ordinarily you will be parsing JSON data programmatically, if you find it helpful to
							see the JSON results in your browser, we recommend using an extention to present it in a readable format, with proper line breaks and indentation.
							The <a href="https://chrome.google.com/webstore/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa/related?hl=en" target="_blank">JSON Formatter</a> extension for Chrome works well.
						</p>
					</div>
					<router-view></router-view>
				</div>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
	export default {
		name: 'Home',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				}
			}
		},
		computed: {
			breadCrumbs() {
				var crumbs = [];
				for (var i = 0; i < this.$route.matched.length; i++) {
					var item = this.$route.matched[i];
					var name = item.meta.title ? item.meta.title : item.name;

					crumbs.push({
						text: name,
						to: { name: item.name }
					});
				}
				return crumbs;
			},
			csharpCode() {
				let code = `string apiKey = "YOUR_API_KEY;"
string url = "${this.siteText.appUrl}/test/clientget";

using var client = new HttpClient();
var message = new HttpRequestMessage(HttpMethod.Get, url);
message.Headers.Add("X-API-Key", apiKey);
var result = await client.SendAsync(message);

//Read the result. Deserialize from Json into a class object as desired. Below shows deserializing to a dictionary. JsonConvert belongs to the Newtonsoft.Json package.
if (result.IsSuccessStatusCode)
{
	var str = await result.Content.ReadAsStringAsync();
	if (!string.IsNullOrEmpty(str))
	{
		var data = JsonConvert.DeserializeObject<Dictionary<string, dynamic>>(str);
	}
}`;

				return code;
			},
			javascriptCode() {
				let code = `import axios from 'axios'; //Our examples use https://axios-http.com/, but you may use any JavaScript HTTP client

const apiKey = 'YOUR_API_KEY';
const url = '${this.siteText.appUrl}/test/clientget';

let headers = {
	headers: { 'X-API-Key':  apiKey }
};

try {
	const response = await axios.get(url, headers);
	let data = response.data; //Json object of results
} catch (error) {
	console.error(error);
}`;

				return code;
			},
			pythonCode() {
				let host = this.siteText.appUrl.replace('http://', '').replace('https://', '');
				let protocolType = this.siteText.appUrl.startsWith('https://') ? 'HTTPSConnection' : 'HTTPConnection';
				let code = `import http.client

connection = http.client.${protocolType}('${host}')
headers = { 'X-API-Key': 'YOUR_API_KEY' }

connection.request('GET', '/test/clientget', None, headers)
response = connection.getresponse()
print(response.read().decode())`;

				return code;
			},
			curlCode() {
				let code = `curl --request GET "${this.siteText.appUrl}/test/clientget" --header "X-API-Key: YOUR_API_KEY"`;

				return code;
			}
		}
	}
</script>

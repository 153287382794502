<template>
	<div>
		<page-authorization-container :page="page">
			<div v-if="hideIfEmpty && items.length < 1"></div>
			<div v-else>
				<h2 v-if="showHeading" class="mb-3">{{heading}}</h2>
				<div v-if="isNullOrEmpty(table.filter) && items.length < 1 && !useFilter" class="mx-auto text-center">
					<p v-if="!isNullOrEmpty(customEmptyMessage)" class="lead">
						{{customEmptyMessage}}
					</p>
					<p v-else class="lead">
						You don't have any {{collectionDescription}} yet.
					</p>

					<div class="border-dash my-4 max-w-400 mx-auto" v-if="showCreate">
						<router-link :to="createRoute" :append="createRouteAppend" class="no-hover d-block p-4">
							<font-awesome-icon :icon="['fas', 'plus']" size="3x" class="text-border" />
							<div class="lead text-muted">Create a new {{itemName}}</div>
						</router-link>
					</div>

					<p class="mt-5" v-if="showHelp">
						Visit the <router-link to="/help" target="_blank">help section</router-link> for an in-depth user guide, SWAT model documentation, and HAWQS data sources.
					</p>
				</div>
				<div v-else class="mx-auto">
					<p v-if="!isNullOrEmpty(hasItemsIntro)">{{hasItemsIntro}}</p>
					<b-row v-if="useFilter || allowExport" class="mb-3">
						<b-col md="6" lg="4">
							<b-form-input v-if="useFilter" v-model="table.filter" placeholder="Search..." @input="filterChg" autofocus></b-form-input>
						</b-col>
						<b-col md="6" lg="8" class="text-right" v-if="allowExport">
							<a :href="`/api/${exportUrl}?access_token=${localStorageToken}`" class="btn btn-info">{{exportTitle}}</a>
						</b-col>
					</b-row>
					<div>						
						<b-table striped responsive class="nowrap-headers mb-3 border-bottom" :small="small" show-empty
								 :items="items" :busy="table.loading"
								 :fields="table.fields"
								 :selectable="selectable" @row-selected="onRowSelected" select-mode="single"
								 :no-local-sorting="true"
								 :sort-by.sync="table.sort"
								 :sort-desc.sync="table.reverse"
								 @sort-changed="sortChg">
							<template v-slot:table-busy>
								<div class="text-center my-4">
									<font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
								</div>
							</template>
							<template v-slot:cell(edit)="data">
								<router-link :to="getEditRoute(data.item)" :append="editRouteAppend" title="Edit/View">
									<font-awesome-icon :icon="['fas', 'edit']" />
								</router-link>
							</template>
							<template v-slot:cell(name)="data">
								<router-link v-if="linkName" :to="getEditRoute(data.item)" :append="editRouteAppend">{{data.item.name}}</router-link>
								<span v-else>{{data.item.name}}</span>
							</template>
							<template v-slot:cell(userName)="data">
								<router-link v-if="linkUserName && !isNullOrEmpty(data.item.userID)" :to="`/admin/users/${data.item.userID}`">{{data.item.userName}}</router-link>
								<span v-else>{{data.item.userName}}</span>
							</template>
							<template v-slot:cell(project)="data">
								<router-link v-if="!isNullOrEmpty(data.item.project)" :to="`/projects/${data.item.projectID}`">{{data.item.project}}</router-link>
								<span v-else></span>
							</template>
							<template v-slot:cell(group)="data">
								<router-link v-if="!isNullOrEmpty(data.item.groupID)" :to="`/groups/${data.item.groupID}`">{{data.item.group}}</router-link>
								<span v-else>{{data.item.group}}</span>
							</template>
							<template v-slot:cell(scenario)="data">
								<router-link v-if="!isNullOrEmpty(data.item.project) && !isNullOrEmpty(data.item.scenario)" :to="`/projects/${data.item.projectID}/scenarios/${data.item.scenarioID}`">{{data.item.scenario}}</router-link>
								<span v-else></span>
							</template>
							<template v-slot:cell(roles)="data">
								<span v-for="(r, i) in data.item.roles" :key="i">
									<b-badge v-if="r === roleNames.pending" variant="warning">Pending</b-badge>
									<b-badge v-if="r === roleNames.admin || r === roleNames.partialAdmin" variant="success">Admin</b-badge>
								</span>
							</template>
							<template v-slot:cell(isLockedOut)="data">
								<b-badge v-if="data.item.isLockedOut" variant="warning">Locked</b-badge>
								<span v-else></span>
							</template>
							<template v-slot:cell(hasRun)="data">
								<font-awesome-icon v-if="data.item.isRunning" :icon="['fas', 'spinner']" spin />
								<font-awesome-icon v-else-if="data.item.hasRun" :icon="['fas', 'check']" />
								<span v-else></span>
							</template>
							<template v-slot:cell(lastModifiedDate)="data">
								{{data.item.lastModifiedDate | date}}
							</template>
							<template v-slot:cell(delete)="data">
								<div v-if="isNullOrEmpty(deleteIfField) || data.item[deleteIfField]">
									<button class="plain text-danger" v-b-tooltip.hover.right="'Delete'" @click="askDelete(data.item.id, data.item.name)">
										<font-awesome-icon :icon="['fas', 'times']" />
									</button>
								</div>
								<div v-else></div>
							</template>
							<template v-slot:cell(reportStatus)="data">
								<b-badge v-if="data.item.reportStatus === 'Submitted'" variant="danger">Submitted</b-badge>
								<b-badge v-if="data.item.reportStatus === 'Working'" variant="warning">Working</b-badge>
								<b-badge v-if="data.item.reportStatus === 'Resolved'" variant="success">Resolved</b-badge>
								<span v-else></span>
							</template>
							<template v-slot:empty>
								<h4 v-if="!isNullOrEmpty(table.filter)">No records found matching "{{table.filter}}".</h4>
								<h4 v-else>You don't have any {{collectionDescription}} yet.</h4>
							</template>
						</b-table>

						<div v-if="!ignoreSortAndPage">
							<fixed-action-bar v-if="pagingFixed" :cols="12" :offset="0" :cols-lg="12" :offset-lg="0">
								<b-pagination class="mb-0" v-if="table.total > table.itemsPerPage" size="md" :total-rows="table.total" v-model="table.currentPage" :per-page="table.itemsPerPage" @change="pageChg" />
								<div class="ml-auto">
									Showing {{showingFirst}} - {{showingLast}}
									of {{table.total}}
								</div>
							</fixed-action-bar>
							<div v-else class="d-flex align-items-center">
								<b-pagination class="mb-0" v-if="table.total > table.itemsPerPage" size="md" :total-rows="table.total" v-model="table.currentPage" :per-page="table.itemsPerPage" @change="pageChg" />
								<div class="ml-auto">
									Showing {{showingFirst}} - {{showingLast}}
									of {{table.total}}
									<a v-if="showRefresh" href="#" @click.prevent="get" title="Refresh table"><font-awesome-icon :icon="['fas', 'redo']" /></a>
								</div>
							</div>
						</div>

						<div class="mt-3" v-if="showCreate">
							<router-link :to="createRoute" :append="createRouteAppend" class="btn btn-success">
								Create a new {{itemName}}
							</router-link>
						</div>
					</div>
				</div>
			</div>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete <strong>{{page.delete.name}}</strong>?
					This action is permanent and cannot be undone. {{extraDeleteMessage}}
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</page-authorization-container>
	</div>
</template>

<script>
	import moment from 'moment';
	import _ from 'underscore';

	export default {
		name: 'GridView',
		props: {
			apiUrl: {
				type: String,
				required: true
			},
			deleteApiUrl: {
				type: String
			},
			fields: {
				type: Array
			},
			routerLinkFields: {
				type: Array,
				default: () => [{ key: 'none', route: 'none' }] // Was griping when empty, even with v-if on length
			},
			useDynamicFields: {
				type: Boolean,
				default: false
			},
			defaultSort: {
				type: String,
				default: 'id'
			},
			defaultReverse: {
				type: Boolean,
				default: false
			},
			collectionName: {
				type: String,
				default: 'items'
			},
			collectionDescription: {
				type: String,
				default: 'items'
			},
			customEmptyMessage: {
				type: String,
				default: ''
			},
			hasItemsIntro: {
				type: String,
				default: ''
			},
			itemName: {
				type: String,
				default: 'item'
			},
			showCreate: {
				type: Boolean,
				default: false
			},
			createRoute: {
				type: String,
				default: ''
			},
			createRouteAppend: {
				type: Boolean,
				default: true
			},
			showHelp: {
				type: Boolean,
				default: false
			},
			extraDeleteMessage: {
				type: String,
				default: ''
			},
			editRoute: {
				type: String,
				default: ''
			},
			editRouteAppend: {
				type: Boolean,
				default: true
			},
			useEditRoutePieces: {
				type: Boolean,
				default: false
			},
			editRoutePieces: {
				type: Array,
				default: () => [{ type: 'text', value: 'none' }] // Was griping when empty, even with v-if on length
			},
			defaultPerPage: {
				type: Number,
				default: 20
			},
			small: {
				type: Boolean,
				default: false
			},
			pagingFixed: {
				type: Boolean,
				default: false
			},
			useFilter: {
				type: Boolean,
				default: false
			},
			allowExport: {
				type: Boolean,
				default: false
			},
			exportUrl: {
				type: String,
				default: ''
			},
			exportTitle: {
				type: String,
				default: 'Download CSV'
			},
			filterFlag: {
				type: String,
				default: ''
			},
			linkName: {
				type: Boolean,
				default: false
			},
			linkUserName: {
				type: Boolean,
				default: false
			},
			ignoreSortAndPage: {
				type: Boolean,
				default: false
			},
			showHeading: {
				type: Boolean,
				default: false
			},
			heading: {
				type: String,
				default: ''
			},
			hideIfEmpty: {
				type: Boolean,
				default: false
			},
			selectable: {
				type: Boolean,
				default: false
			},
			deleteIfField: {
				type: String,
				default: ''
			},
			showRefresh: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {
				page: {
					errors: [],
					loading: true,
					showLogin: false,
					delete: {
						show: false,
						id: undefined,
						name: '',
						errors: [],
						saving: false
					},
					export: {
						errors: [],
						loading: false
					}
				},
				table: {
					loading: false,
					fields: [],
					sort: this.defaultSort,
					specialSort: undefined,
					reverse: this.defaultReverse,
					currentPage: 1,
					itemsPerPage: this.defaultPerPage,
					total: 0,
					filter: null
				},
				items: []
			}
		},
		computed: {
			showingFirst() {
				return (this.table.currentPage-1) * this.table.itemsPerPage + 1
			},
			showingLast() {
				var max = (this.table.currentPage-1) * this.table.itemsPerPage + this.table.itemsPerPage
				return max > this.table.total ? this.table.total : max;
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get',
			async filterFlag() {
				if (!this.isNullOrEmpty(this.filterFlag)) {
					this.table.currentPage = 1;
					this.table.filter = null;
				}
				await this.get();
			}
		},
		methods: {
			getDb() {
				if (this.ignoreSortAndPage) return this.$http.get(this.apiUrl, this.getTokenHeader());
				var sort = this.table.reverse ? this.table.sort + '_desc' : this.table.sort;
				var filter = this.useFilter && this.table.filter !== null ? `&filter=${encodeURIComponent(this.table.filter)}` : '';
				var flag = !this.isNullOrEmpty(this.filterFlag) ? `&flag=${encodeURIComponent(this.filterFlag)}` : '';
				return this.$http.get(`${this.apiUrl}?sort=${sort}&page=${this.table.currentPage}&perPage=${this.table.itemsPerPage}${filter}${flag}`, this.getTokenHeader());
			},
			async filterChg() {
				this.table.currentPage = 1;
				_.debounce(await this.get(), 500)
				//await this.get();
			},
			async pageChg(page) {
				this.table.currentPage = page;
				await this.get();
			},
			async sortChg(ctx) {
				this.table.sort = ctx.sortBy;
				this.table.reverse = ctx.sortDesc;
				this.table.specialSort = undefined;

				var prop = this.routerLinkFields.find(o => o.key == ctx.sortBy);
				if (prop != null) {
					this.table.specialSort = ctx.sortBy + '_id';
				}

				await this.get();
			},
			async get() {
				this.page.errors = [];
				this.table.loading = true;

				try {
					const response = await this.getDb();
					this.log(response.data);

					if (response.data[this.collectionName].length == 0 && this.table.currentPage > 1) {
						this.table.currentPage--;
						await this.get();
					}

					this.items = response.data[this.collectionName];
					this.table.total = response.data.total;

					//Set table fields
					if (this.items.length > 0) {
						if (this.useDynamicFields) {
							var keys = Object.keys(this.items[0]);

							for (var i = 0; i < keys.length; i++) {
								if ((keys[i] != 'id' || !this.dynamicFieldsHideId) && !this.hideFields.includes(keys[i])) {
									var meta = this.getMeta(keys[i], this.items[0][keys[i]]);
									this.table.fields.push({ key: keys[i], label: meta.label, sortable: true, 'class': meta.css, formatter: meta.formatter });
								}
							}
						} else {
							this.table.fields = this.fields;
						}
					}
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.table.loading = false;
				this.page.loading = false;
			},
			/*async exportTable() {
				this.page.export.errors = [];
				this.page.export.loading = true;

				try {
					const response = await this.$http.get(this.exportUrl, this.getTokenHeader());
					this.log(response);
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.export.errors = this.logError(error);
				}

				this.page.export.loading = false;
			},*/
			getMeta(field, item) {
				var label = field[0].toUpperCase() + field.substring(1);
				var css = 'text-right';
				var formatter = (value) => { return isNaN(value) || Number.isInteger(Number(value)) ? value : Number(value).toFixed(3) }

				var textFields = [
					'name',
					'description'
				];

				if (textFields.includes(field) || isNaN(item)) {
					css = 'text-left';
					formatter = undefined;
				}

				return {
					label: label,
					css: css,
					formatter: formatter
				};
			},
			askDelete(id, name) {
				this.page.delete.id = id;
				this.page.delete.name = name;
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`${this.deleteApiUrl}/${this.page.delete.id}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.table.currentPage = 1;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			getEditRoute(item) {
				if (!this.useEditRoutePieces)
					return this.editRoute + item.id;

				var r = '';
				for (var i = 0; i < this.editRoutePieces.length; i++) {
					if (this.editRoutePieces[i].type == 'text') {
						r += '/' + this.editRoutePieces[i].value;
					} else {
						r += '/' + item[this.editRoutePieces[i].value];
					}
				}

				return r;
			},
			onRowSelected(items) {
				this.$emit('selectionChanged', items);
			}
		}
	}
</script>

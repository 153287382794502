import Vue from 'vue';

import PageLoading from '@/components/helpers/PageLoading';
import PageRunning from '@/components/helpers/PageRunning';
import ErrorList from '@/components/helpers/ErrorList';
import SaveButton from '@/components/helpers/SaveButton';
import BackButton from '@/components/helpers/BackButton';
import SuccessAlert from '@/components/helpers/SuccessAlert';
import FixedActionBar from '@/components/helpers/FixedActionBar';
import CheckIcon from '@/components/helpers/CheckIcon';
import PageAuthorizationContainer from '@/components/helpers/PageAuthorizationContainer';
import LoginForm from '@/components/LoginForm';
import ApiLinker from '@/components/ApiLinker';

Vue.component('page-loading', PageLoading);
Vue.component('page-running', PageRunning);
Vue.component('error-list', ErrorList);
Vue.component('save-button', SaveButton);
Vue.component('back-button', BackButton);
Vue.component('success-alert', SuccessAlert);
Vue.component('fixed-action-bar', FixedActionBar);
Vue.component('check-icon', CheckIcon);
Vue.component('page-authorization-container', PageAuthorizationContainer);
Vue.component('login-form', LoginForm);
Vue.component('api-linker', ApiLinker);

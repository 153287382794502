<template>
	<page-authorization-container :page="page" require-admin>
		<h2 class="mb-3">API Request</h2>

		<b-tabs v-model="page.tabIndex" content-class="mt-4" class="page-nav-tabs">
			<b-tab title="Info">
				<b-row>
					<b-col md="5">
						<table class="table table-inner-borders mb-4">
							<tr>
								<th class="min">Name</th>
								<td>{{data.name}}</td>
							</tr>
							<tr>
								<th class="min">API Client</th>
								<td><router-link :to="`/admin/clients/${data.apiClient.id}`">{{data.apiClient.name}} - {{data.apiClient.userName}}</router-link></td>
							</tr>
							<tr>
								<th class="min">Created</th>
								<td>{{data.createdDate | date}}</td>
							</tr>
							<tr>
								<th class="min">Last modified</th>
								<td>{{data.lastModifiedDate | date}}</td>
							</tr>
							<tr>
								<th class="min">Start date</th>
								<td>{{data.startRunDate | date}}</td>
							</tr>
						</table>
					</b-col>
					<b-col md="7">
						<table class="table table-inner-borders mb-4">
							<tr>
								<th class="min">Status</th>
								<td>{{data.status}} - {{data.currentProgress}}% - {{data.statusMessage}}</td>
							</tr>
							<tr>
								<th class="min">Run time</th>
								<td>{{data.runtime}}</td>
							</tr>
							<tr>
								<th class="min">Errors</th>
								<td><b-form-textarea v-model="data.exceptionMessage" rows="15"></b-form-textarea></td>
							</tr>
						</table>
					</b-col>
				</b-row>
			</b-tab>
			<b-tab title="Inputs">
				<highlightjs language="javascript" :code="inputsCode" />
			</b-tab>
			<b-tab title="Outputs">
				<highlightjs language="javascript" :code="outputsCode" />
			</b-tab>
		</b-tabs>

		<fixed-action-bar :cols-lg="10" :offset-lg="2">
			<save-button :saving="page.loading" @click.native="get" class="mr-2" text="Refresh" />
			<b-button :to="`/docs/projects/${data.id}`" target="_blank" variant="primary" class="mr-2">Check Status</b-button>
			<back-button class="btn btn-secondary mr-2" />
			<b-button v-if="isAuthorized(roleNames.admin)" type="button" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
		</fixed-action-bar>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete this API request? All associated files will also be deleted.
				This action is permanent and cannot be undone.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</page-authorization-container>
</template>

<script>
	export default {
		name: 'AdminRequestsView',
		data() {
			return {
				id: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: [],
					tabIndex: 0,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				data: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`admin/apirequests/${this.$route.params.id}`, this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`admin/apirequests/delete/${this.$route.params.id}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: 'AdminRequests' });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		},
		computed: {
			inputsCode() {
				return this.isNullOrEmpty(this.data.inputs) ? '' : JSON.stringify(this.data.inputs, null, 4);
			},
			outputsCode() {
				return this.isNullOrEmpty(this.data.outputs) ? '' : JSON.stringify(this.data.outputs, null, 4);
			}
		}
	}
</script>

<template>
	<div>
		<div v-if="$route.name == 'AdminClients'">
			<page-authorization-container :page="page" require-admin>
				<h2 class="mb-3">API Clients</h2>

				<p>Add a new client:</p>
				<error-list :errors="page.saveErrors"></error-list>
				<b-form inline>
					<b-form-select v-model="name" :options="options.names" class="mr-1">
						<template #first>
							<b-form-select-option :value="null" disabled>-- Please select a key type --</b-form-select-option>
						</template>
					</b-form-select>
					<b-typeahead ref="usersTypeahead" placeholder="User name"
								 :data="userNames"
								 v-model="usersSearch"
								 :max-matches="100">
					</b-typeahead>

					<save-button type="button" :saving="page.saving" @click.native="add" text="Add" variant="success" class="mx-1" />
				</b-form>

				<hr class="my-4" />

				<grid-view api-url="admin/apiclients" use-filter
						   :fields="table.fields"
						   :default-sort="table.sort" :default-reverse="table.reverse"
						   collection-description="clients" item-name="client"></grid-view>
			</page-authorization-container>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import _ from 'underscore';

	export default {
		name: 'AdminClients',
		components: {
			GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: []
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'name', sortable: true },
						{ key: 'userName', sortable: true },
						{ key: 'numRequests', label: '# API Requests', sortable: true },
						{ key: 'numProjects', label: '# API Projects', sortable: true }
					],
					sort: 'userName',
					reverse: false,
					itemsPerPage: 50
				},
				usersSearch: '',
				userNames: [],
				name: null,
				options: {
					names: []
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			usersSearch: _.debounce(function (query) { this.findUsers(query) }, 500)
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('admin/apiclients/options', this.getTokenHeader());
					this.log(response.data);
					this.options = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async add() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					let data = {
						name: this.name,
						userName: this.usersSearch
					};

					const response = await this.$http.post('admin/apiclients/post', data, this.getTokenHeader());
					this.log(response.data);
					this.$router.push({ name: 'AdminClientsView', params: { id: response.data } }).catch(err => { this.log(err); });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			async findUsers(query) {
				try {
					const response = await this.$http.get(`admin/users/find/${query}`, this.getTokenHeader());
					this.userNames = response.data != '' ? response.data : [];
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.copy.errors = this.logError(error);
				}
			}
		}
	}
</script>

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';

import './plugins/bootstrap-vue';
import './plugins/font-awesome';
import './plugins/custom-components';
import './plugins/filters';
import './plugins/global-mixin';
import './plugins/highlight';

import StatusHub from './plugins/status-hub';
Vue.use(StatusHub);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

Vue.prototype.$http = axios.create();

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')

<template>
	<page-authorization-container :page="page" no-auth>
		<h2 class="mb-3">API Project Status</h2>

		<div v-if="isNullOrEmpty(id)">
			<b-alert variant="warning" show>
				Please provide the ID number of the request in the URL in your browser.
			</b-alert>
		</div>
		<div v-else>
			<b-form @submit.prevent="get">
				<b-form-group label="API Key">
					<b-form-input v-model="apiKeys.shared" type="text" required></b-form-input>
				</b-form-group>

				<save-button variant="primary" @saving="page.project.loading" text="Get Project Status"></save-button>
			</b-form>

			<div v-if="page.project.loaded">
				<hr class="my-4" />
				<div v-if="data.status.progress < 100" class="max-w-400 py-5 mx-auto text-center">
					<b-progress :value="data.status.progress" :max="100" animated></b-progress>
					<p>
						{{data.status.message}}
					</p>

					<p class="text-muted mt-5">
						<small>
							Progress not updating? <a href="#" @click.prevent="get">Refresh now.</a>
							<br /><a href="#" @click.prevent="page.abort.show = true" class="text-danger">Cancel scenario run.</a>
						</small>
					</p>
				</div>
				<div v-else-if="!isNullOrEmpty(data.status.errorStackTrace)">
					<h2 class="mb-3">
						<font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="icon mr-1 text-warning" />
						An error occurred
					</h2>
					<p>
						Errors can be the result of a bug in the system or input data misconfiguration.
						Please see the full exception message below.
					</p>

					<hr class="my-4" />

					<h5>{{data.status.message}}</h5>
					<p>
						<b-form-textarea v-model="data.status.errorStackTrace" rows="15"></b-form-textarea>
					</p>
				</div>
				<div v-else-if="data.status.message.includes('Aborted')">
					<b-alert variant="warning" show>Project canceled.</b-alert>
				</div>
				<div v-else>
					<b-alert variant="success" show>Project run complete. Download your files below:</b-alert>
					<ul>
						<li v-for="(f, i) in data.output" :key="i"><a :href="f.url">{{f.name}} ({{f.format}})</a></li>
					</ul>
				</div>
			</div>
		</div>

		<b-modal v-model="page.abort.show" size="md" title="Confirm run cancellation" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.abort.errors"></error-list>

			<p>
				Are you sure you want to abort the current run of this scenario?
				All files related to the model run will be deleted.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.abort.saving" @click.native="abortRun" text="Yes, Abort" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.abort.show = false" class="ml-1">No</b-button>
			</div>
		</b-modal>
	</page-authorization-container>
</template>

<script>
	//import shajs from 'sha.js';

	export default {
		name: 'DocsProjectsGet',
		data() {
			return {
				id: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					project: {
						errors: [],
						loading: false,
						loaded: false
					},
					init: true,
					abort: {
						show: false,
						errors: [],
						saving: false
					}
				},
				data: {
					status: {
						progress: 0,
						message: null,
						errorStackTrace: null
					},
					output: []
				},
				apiKeys: {
					shared: null,
					secret: null
				}
			}
		},
		beforeDestroy() {
			this.$statusHub.apiRequestClosed(this.id);
			this.$statusHub.$off('api-request-status-changed', this.onStatusChanged);
		},
		async created() {
			await this.loadApiKeys();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.project.errors = [];
				this.page.project.loading = true;
				this.page.project.loaded = false;

				try {
					let headers = {
						headers: { 'X-API-Key': this.apiKeys.shared }
					};

					const response = await this.$http.get(`projects/${this.id}`, headers);
					this.log(response.data);
					this.data = response.data;
					this.page.project.loaded = true;
				} catch (error) {
					this.page.project.errors = this.logError(error);
				}

				this.page.project.loading = false;

				if (this.page.init) {
					this.$statusHub.apiRequestOpened(this.id);
					this.$statusHub.$on('api-request-status-changed', this.onStatusChanged);
					this.page.init = false;
				}
			},
			async abortRun() {
				try {
					let headers = {
						headers: { 'X-API-Key': this.apiKeys.shared }
					};

					await this.$http.patch(`projects/cancel/${this.id}`, {}, headers);
					this.page.abort.show = false;
					await this.get();
				} catch (error) {
					this.page.abort.errors = this.logError(error);
				}
			},
			async loadApiKeys() {
				if (this.isAuthenticated) {
					this.page.project.loading = true;
					try {
						const response = await this.$http.get(`admin/apiclients/find/${this.user.userName}`, this.getTokenHeader());
						this.apiKeys.shared = response.data.sharedKey;
						this.apiKeys.secret = response.data.secretKey;
					} catch (error) {
						this.logError(error);
					}
					this.page.project.loading = false;
				}
			},
			async onStatusChanged(data) {
				if (this.id != data.requestID) return;
				if (data.progress == 100) {
					await this.get();
				}

				this.data.status.progress = data.progress;
				this.data.status.message = data.statusMessage;
			}
		}
	}
</script>

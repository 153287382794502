<template>
	<div>
		<div v-if="$route.name == 'AdminRequests'">
			<page-authorization-container :page="page" require-admin>
				<h2 class="mb-3">API Requests</h2>

				<grid-view api-url="admin/apirequests" use-filter
						   :fields="table.fields"
						   :default-sort="table.sort" :default-reverse="table.reverse"
						   collection-description="clients" item-name="client"></grid-view>
			</page-authorization-container>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';

	export default {
		name: 'AdminRequests',
		components: {
			GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'name', sortable: true },
						{ key: 'lastModifiedDate', sortable: true, class: 'nowrap' },
						{ key: 'apiClientType', sortable: true },
						{ key: 'userName', sortable: true },
						{ key: 'status', sortable: true }
					],
					sort: 'lastModifiedDate',
					reverse: true,
					itemsPerPage: 50
				}
			}
		}
	}
</script>

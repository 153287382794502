<template>
	<div>
		<div v-if="$route.name == 'AdminUsers'">
			<page-authorization-container :page="page" require-admin>
				<h2 class="mb-3">API Administrators</h2>

				<p>
					Users must already exist in the main {{siteText.appName}} users database and be in an administrative role. Grant or remove access using the form below.
				</p>

				<error-list :errors="page.saveErrors"></error-list>
				<b-form inline>
					<b-typeahead ref="usersTypeahead" placeholder="User name"
								 :data="userNames"
								 v-model="usersSearch"
								 :max-matches="100">
					</b-typeahead>

					<save-button type="button" :saving="page.saving" @click.native="grantAccess" text="Grant Access" variant="success" class="mx-1" />
					<save-button type="button" :saving="page.saving" @click.native="removeAccess" text="Remove Access" variant="danger" />
				</b-form>

				<hr class="my-4" />

				<grid-view ref="gridTable" api-url="admin/users" use-filter
						   :fields="table.fields"
						   :default-sort="table.sort" :default-reverse="table.reverse"
						   collection-description="users"
						   item-name="user"
						   extra-delete-message="All projects, scenarios, and files for this user will be removed."></grid-view>
			</page-authorization-container>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';
	import _ from 'underscore';

	export default {
		name: 'AdminUsers',
		components: {
			GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: []
				},
				table: {
					fields: [
						{ key: 'roles', label: '' },
						{ key: 'lastLoginDate', label: 'Last Log-in', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY h:mm a') } },
						{ key: 'userName', sortable: true },
						{ key: 'email', sortable: true },
						{ key: 'firstName', sortable: true },
						{ key: 'lastName', sortable: true },
						{ key: 'affiliation', sortable: true },
						{ key: 'organizationCategory', label: 'Category', sortable: true },
						{ key: 'country', sortable: true },
						{ key: 'state', sortable: true },
						{ key: 'registrationDate', label: 'Registered', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY') } },
						{ key: 'isLockedOut', label: 'Locked?', sortable: true }
					],
					sort: 'lastLoginDate',
					reverse: true,
					itemsPerPage: 50
				},
				usersSearch: '',
				userNames: []
			}
		},
		watch: {
			usersSearch: _.debounce(function (query) { this.findUsers(query) }, 500)
		},
		methods: {
			async grantAccess() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					const response = await this.$http.post('admin/users/addtoapp', { value: this.usersSearch }, this.getTokenHeader());
					this.log(response.data);
					await this.$refs.gridTable.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			async removeAccess() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					const response = await this.$http.post('admin/users/removefromapp', { value: this.usersSearch }, this.getTokenHeader());
					this.log(response.data);
					await this.$refs.gridTable.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			async findUsers(query) {
				try {
					const response = await this.$http.get(`admin/users/find/${query}`, this.getTokenHeader());
					this.userNames = response.data != '' ? response.data : [];
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.copy.errors = this.logError(error);
				}
			}
		}
	}
</script>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		token: localStorage.getItem('auth_token') || '',
		user: {
			userName: undefined,
			email: undefined,
			firstName: undefined,
			lastName: undefined,
			emailConfirmed: undefined,
			token: undefined,
			roles: []
		},
		siteText: {
			appName: undefined,
			appUrl: undefined,
			mainWebUrl: undefined
		},
		rememberMeToken: undefined
	},
	mutations: {
		setupSite(state, payload) {
			state.siteText = payload.siteText;
		},
		login(state, payload) {
			state.token = payload.token;
			state.user = payload.user;
		},
		logout(state) {
			state.token = '';
			state.user = {
				userName: undefined,
				email: undefined,
				firstName: undefined,
				lastName: undefined,
				emailConfirmed: undefined,
				token: undefined,
				roles: []
			};
		},
		storeRememberMeToken(state, payload) {
			state.rememberMeToken = payload.token;
		}
	},
	getters: {
		isAuthenticated: state => !!state.token,
		user: state => state.user,
		roles: state => state.user.roles,
		token: state => state.token,
		rememberMeToken: state => state.rememberMeToken,
		siteText: state => state.siteText
	},
})
